import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DeckEditor from "./Components/DeckEditor/DeckEditor";
import DeckEditorLink from "./Components/DeckEditor/DeckEditorLink";
import DeckBrowser from './Components/DeckBrowser/DeckBrowser'
import Home from "./Components/Horde";
import HomePage from "./Components/HomePage/HomePage";
import { LandscapeOnly } from './Components/Commons/Commons';
import logo from './logo.svg';
import Horde from './Components/Horde'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './firebase.js'
import { DeckBrowserLink, DeckBrowserPublicLink } from './Components/DeckBrowser/DeckBrowserLink';
import CollectionManager from './Components/Manager/CollectionManager';
import MultiplayerGame from './Components/Game/Multiplayer/MultiplayerGame.jsx';
import PeerMultiplayerGame from './Components/Game/Multiplayer/PeerMultiplayerGame.jsx';
import PeerMultiplayerHostGame from './Components/Game/Multiplayer/PeerMutliplayerHostGame.jsx';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  /*
  <React.StrictMode>
    <App />
  </React.StrictMode>*/
  <div className="App">
    <script src=''></script>
    <header className="App-header">
      <BrowserRouter>
        <Routes>
          <Route path="/deck" element={<LandscapeOnly component={<DeckEditor />}/>} />
          <Route path="/decks" element={<DeckBrowser />} />
          <Route path="/yourdecks" element={<CollectionManager />} />
          <Route path="/deck/:id" element={<DeckEditorLink />} />
          <Route path="/decks/public/:query" element={<DeckBrowserPublicLink />} />
          <Route path="/decks/:query" element={<DeckBrowserLink />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/play/:gameId" element={<PeerMultiplayerGame />} />
        </Routes>
      </BrowserRouter>

    </header>
  </div>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();