import React, { useRef } from "react"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom"

import { UserName, Button, BigButton } from "../Commons/Commons";
import { HalfScreenPanel } from "../Commons/HalfScreenPanel/HalfScreenPanel";
import './NavBar.css'
import './Login.css'

import { NavLink, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword, onAuthStateChanged, signOut, sendPasswordResetEmail } from 'firebase/auth'
import useGoogleSheets from 'use-google-sheets';
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db, auth } from '../../firebase';

import PatreonIcon from '../../assets/Icons/patreon.png'
import DiscordIcon from '../../assets/Icons/discord.png'
import DeckEditorIcon from '../../assets/Icons/edit.png'
import ThanksIcon from '../../assets/Icons/coeur.png'

function NewNavBar(props) {
    const [showThanks, setShowThanks] = useState(false)
    const [showLogin, setShowLogin] = useState(false)
    const [open, setOpen] = useState(props.showNavbarByDefault == undefined ? false : props.showNavbarByDefault)
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    useEffect(() => {
        const auth = getAuth();
        const userData = JSON.parse(sessionStorage.getItem("userData"))

        if (userData == undefined) {
            setIsLoggedIn(false)
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    setIsLoggedIn(true)
                } else {
                    setIsLoggedIn(false)
                }
            });
        } else {
            setIsLoggedIn(true)
        }
    }, [])

    return (
        <div>
            <Navbar expand="xl" className="bg-body-tertiary newNavBar navbar-dark">
                <Container>
                    <Navbar.Brand href="/">
                        <img src={require('./../../assets/appIcon.png')} className="d-inline-block align-middle shadowed logo" />
                        Against the Horde
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Link to="/" className={"link" + (props.selected == "Home" ? " selected" : "")}>Play</Link>
                            <Link to="/yourdecks" className={"link" + (props.selected == "Collection" ? " selected" : "") + (!isLoggedIn ? " disabled" : "")}>Manage decks</Link>
                            <Link to="/decks" className={"link" + (props.selected == "Browser" ? " selected" : "")}>Explore decks</Link>
                        </Nav>
                        <Nav>
                            <a class="link link-discord" onClick={() => { setShowThanks(true) }}><img class="icon" src={ThanksIcon} className="d-inline-block align-middle icon" />Thanks</a>
                            <a href="https://discord.com/invite/wzm7bu6KDJ" class="link"><img class="icon" src={DiscordIcon} className="d-inline-block align-middle icon" />Discord</a>
                            <a href="https://www.patreon.com/user?u=91929461" class="link link-patreon"><img class="icon" src={PatreonIcon} className="d-inline-block align-middle icon" />Support</a>
                        </Nav>
                        <Login_User_Panel setShowLogin={() => { setShowLogin(true) }} />
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {
                showLogin ? (
                    <div>
                        {/*
                                                <HalfScreenPanel
                            component={<LoginPanel />}
                            imageName={"img_intro.jpg"}
                            copyright="Cristi Balanescu"
                            hidePanel={() => { setShowLogin(false) }}
                        />
                        */}
                        <HalfScreenPanel
                            component={<LoginPanel />}
                            imageName={"img_login.jpg"}
                            copyright="Piotr Dura"
                            hidePanel={() => { setShowLogin(false) }}
                        />
                    </div>
                ) : (<div></div>)
            }
            {
                showThanks ? (<HalfScreenPanel
                    component={<ThanksPanel />}
                    imageName="img_thanks.jpg"
                    copyright="Chris Ostrowski"
                    hidePanel={() => { setShowThanks(false) }}
                />) : (<div></div>)
            }
        </div>

    );
    function Login_User_Panel(props) {
        const navigate = useNavigate();
        const [isLogin, setIsLogin] = React.useState(false)
        const [user, setUser] = React.useState(false)
        const isLoading = useRef(false)

        const { data, loading, error } = useGoogleSheets({
            apiKey: "AIzaSyC1IFQjAzoPL7xKyyitEc7uK3_3P0Ql6bU",
            sheetId: "1S46M2fgGZ1zVuxLLeCV1wUlCJ0nMZNDvtCD_2JTFp9s",
            sheetsOptions: [{ id: 'patreons-name', headerRowIndex: 1 }],
        });

        function handleLogout() {
            const auth = getAuth();
            signOut(auth).then(() => {
                // Sign-out successful.
                window.location.reload(false)
                sessionStorage.removeItem("userData")
                console.log("Signed out successfully")
            }).catch((error) => {
                // An error happened.
            });
        }

        useEffect(() => {
            const userData = JSON.parse(sessionStorage.getItem("userData"))

            if (userData != undefined) {
                setIsLogin(true)
            }

            const auth = getAuth();
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/firebase.User
                    const uid = user.uid;
                    // ...
                    console.log("uid", uid)
                    setIsLogin(true)
                    setUser(user)
                    if (userData == undefined) {
                        sessionStorage.setItem("userData", JSON.stringify({
                            uid: uid,
                            decks: undefined,
                            shouldUpdateDecks: false,
                            userCollection: undefined
                        }))
                    }
                } else {
                    // User is signed out
                    // ...
                    console.log("user is logged out")
                    setIsLogin(false)
                    setUser(false)
                    sessionStorage.removeItem("userData")
                }
            });
        }, [])


        if (loading == false) {
            if (error == null) {
                loadPatreonsIfNeeded()
            } else {
                console.log(error)
            }
        }

        async function loadPatreonsIfNeeded() {
            const patreonData = JSON.parse(sessionStorage.getItem("patreonData"))

            if (patreonData == undefined && !isLoading.current) {
                console.log("Call sheet")
                isLoading.current = true
                const sheetData = data[0]
                const names = sheetData.data[0].value
                const patreonsLvl = sheetData.data.slice(1, sheetData.data.length)

                sessionStorage.setItem("patreonData", JSON.stringify({
                    names: names,
                    patreonsLvl: patreonsLvl
                }))
                isLoading.current = false
            }
        }

        if (isLogin) {
            return (
                <div class="d-flex flex-row login-username-logout">
                    <div class="login-username">
                        <UserName uid={user.uid} displayName={user.displayName} />
                    </div>
                    <div class="d-flex align-items-center">
                        <img class="logout-button" src={require("../../assets/Icons/deconnexion.png")} onClick={handleLogout} />
                    </div>
                </div>
            )
        }

        return (
            <Button text="Login" isPurple={true} clicked={() => { props.setShowLogin() }} />
        )
    }

    function ThanksPanel(props) {
        const patreonData = JSON.parse(sessionStorage.getItem("patreonData"))
        let names = ""
        if (patreonData != undefined) {
            names = patreonData.names
        }

        return (
            <div class='thanks-panel'>
                <h2>Thanks to my Patreon</h2>
                <p>{names}<br /><br /></p>

                <p>Thanks to everyone who played the iOS app. And an even bigger thanks to those who subscribed to it and helped me cover the cost.</p>
                <p>Thanks to my playgroup for testing the app since its early draft.</p>
                <p>Heart icon by Nur syifa fauziah. Deck edit icon by Pixel perfect.</p>

                <br /><h2>Download links</h2>
                <p>Links to the iOS app for Against the Horde and for my Planechase app.</p>
                <span>
                    <div>
                        <h1>Against the Horde</h1>
                        <a href="https://apps.apple.com/us/app/against-the-horde/id1631351942"><img src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" /></a>
                    </div>
                    <div>
                        <h1>Planechase Companion</h1>
                        <a href="https://apps.apple.com/us/app/planechase-companion/id6445894290"><img src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" /></a>
                    </div>
                </span>
            </div>
        )
    }

    function LoginPanel(props) {
        const [option, setOption] = React.useState(1)
        const navigate = useNavigate()
        const [errorMessage, setErrorMessage] = React.useState("")

        function submit(event) {
            event.preventDefault();

            if (option === 1) {
                signIn()
            } else if (option === 2) {
                signUp()
            } else if (option === 3) {
                forgot()
            }
        }

        async function signIn() {
            let mail = document.getElementById("email").value
            let password = document.getElementById("password").value
            setErrorMessage("")

            const auth = getAuth();

            signInWithEmailAndPassword(auth, mail, password)
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    window.location.reload(false)
                    console.log(user);
                })
                .catch((error) => {
                    setErrorMessage(error.message)
                });
        }

        async function signUp() {
            let mail = document.getElementById("email").value
            let name = document.getElementById("pseudo").value
            let password = document.getElementById("password").value
            let repeat_password = document.getElementById("repeat-password").value
            if (password != repeat_password) {
                setErrorMessage("Password doesn't match")
                return
            }
            setErrorMessage("Please wait ...")

            const auth = getAuth();

            await createUserWithEmailAndPassword(auth, mail, password)
                .then(async (userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    console.log(user);

                    // Set username
                    updateProfile(auth.currentUser, {
                        displayName: name
                    }).catch((error) => {
                        setErrorMessage(error.message)
                    });

                    await setDoc(doc(db, "usersCollection", user.uid), {
                        collection: [
                            "sOnCKduUDPtELqms0SXX",
                            "cJqFVGRIpDiHqOxpY8sq",
                            "Qgkkg2iozPrqds1jOFaL",
                            "WWAzdmLRmhtfVWGjMMP7",
                            "cTCVrZxuUJuqyiMNRj88",
                            "xNXilrTfTOj6ajmfRKbl",
                            "zouNmM7ZxNdxnp5pBj4c"
                        ]
                    }).then(() => {
                        // Profile updated!
                        window.location.reload(false)
                    }).catch((error) => {
                        setErrorMessage(error.message)
                    });
                })
                .catch((error) => {
                    setErrorMessage(error.message)
                });
        }

        function forgot() {
            let mail = document.getElementById("email").value

            sendPasswordResetEmail(auth, mail)
                .then(() => {
                    setErrorMessage("An email has been sent to " + mail)
                })
                .catch((error) => {
                    setErrorMessage(error.message)
                });
        }

        return (
            <div class="login-panel">
                <div class="container">
                    <header>
                        <div className={'header-headings ' + (option === 1 ? 'sign-in' : (option === 2 ? 'sign-up' : 'forgot'))}>
                            <span>Sign in to your account</span>
                            <span>Create an account</span>
                            <span>Reset your password</span>
                        </div>
                    </header>
                    <ul className='login-options d-flex justify-content-between w-100 p-0'>
                        <li className={option === 1 ? 'active' : ''} onClick={() => setOption(1)}>Sign in</li>
                        <li className={option === 2 ? 'active' : ''} onClick={() => setOption(2)}>Sign up</li>
                        <li className={option === 3 ? 'active' : ''} onClick={() => setOption(3)}>Forgot</li>
                    </ul>
                    <p class="text-start">{errorMessage}</p>
                    <Form option={option} />
                </div>
            </div>
        )

        function Form({ option }) {
            return (
                <form className='account-form' onSubmit={submit}>
                    <div className={'account-form-fields ' + (option === 1 ? 'sign-in' : (option === 2 ? 'sign-up' : 'forgot'))}>
                        <input class="blurred-background" id='email' name='email' type='email' placeholder='E-mail' required />
                        <input class="blurred-background" id='pseudo' name='pseudo' type='text' placeholder='Name' maxlength="15" required={option === 2 ? true : false} disabled={option === 1 || option === 3 ? true : false} />
                        <input class="blurred-background" id='password' name='password' type='password' placeholder='Password' required={option === 1 || option === 2 ? true : false} disabled={option === 3 ? true : false} />
                        <input class="blurred-background" id='repeat-password' name='repeat-password' type='password' placeholder='Repeat password' required={option === 2 ? true : false} disabled={option === 1 || option === 3 ? true : false} />
                    </div>
                    <button className='btn-submit-form' type='submit'>
                        {option === 1 ? 'Sign in' : (option === 2 ? 'Sign up' : 'Reset password')}
                    </button>
                </form>
            )
        }
    }
}

export default NewNavBar