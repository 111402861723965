import React, { useEffect, useRef, useState } from "react";
import './DeckEditor.css'
import '../Commons/Commons.css'
import { Button, BigButton, SelectableElement, UserName, CardIMG, DeckArt, WithHelp } from '../Commons/Commons.jsx'
import { CustomScrollContainer } from '../Commons/CustomScrollContainer'
import FlashBackIcon from './../../assets/Icons/flashback.png'
import FlashBackIconThenExile from './../../assets/Icons/flashbackThenExile.png'
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import SocialIcon from '../../assets/Icons/editer.png'
import CustomCardIcon from '../../assets/Icons/art.png'
import { doc, getDoc, setDoc, addDoc, collection, exists, updateDoc, arrayUnion } from "firebase/firestore";
import { onAuthStateChanged } from 'firebase/auth';
import { db, auth } from '../../firebase';
import NewNavBar from "../HomePage/NewNavBar.jsx";
import PlayablePanel from "../Commons/PlayablePanel/PlayablePanel.jsx";
import { HalfScreenPanel } from "../Commons/HalfScreenPanel/HalfScreenPanel.jsx";
import { FullScreenPanel } from "../Commons/FullScreenPanel/FullScreenPanel.jsx";
const { DECKS } = require("../../utils/DB");

class DeckEditor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            cardToShow: /*this.newCard(1, "011f805f-3ebb-4748-a5de-8a10b5f47dcd", "creature", false, "azezae")*/false,
            searchResults: [],
            searchInfoText: "",
            onlySearchTokens: false,
            selectedDeckList: 1,
            deckSelectionInfo: "Cards and tokens in the Horde library",
            deckList_creatures: [],
            deckList_tokens: [],
            deckList_instantsAndSorceries: [],
            deckList_artifactsAndEnchantments: [],
            lategame: [],
            tokens: [],
            weak: [],
            powerful: [],
            showReprintPicker: false,
            showArtPicker: false,
            showSavePanel: false,
            showCustomCardPanel: false,
            showInit: true,
            showSocialPanel: false,
            TMP_isDeckListValid: false,
            TMP_Decklist: "",
            TMP_Decklist_Classic: "",
            TMP_IsImportingDecklist: false,
            TMP_ImportErrorMessage: "",
            reprints: [],/*[this.newCard(1, "011f805f-3ebb-4748-a5de-8a10b5f47dcd", "creature", false, "azezae"), this.newCard(1, "011f805f-3ebb-4748-a5de-8a10b5f47dcd", "creature", false, "azezae"), this.newCard(1, "011f805f-3ebb-4748-a5de-8a10b5f47dcd", "creature", false, "azezae"), this.newCard(1, "011f805f-3ebb-4748-a5de-8a10b5f47dcd", "creature", false, "azezae"), this.newCard(1, "011f805f-3ebb-4748-a5de-8a10b5f47dcd", "creature", false, "azezae"), this.newCard(1, "011f805f-3ebb-4748-a5de-8a10b5f47dcd", "creature", false, "azezae"), this.newCard(1, "011f805f-3ebb-4748-a5de-8a10b5f47dcd", "creature", false, "azezae")]*/
            deckInfo: false,
            deckId: false,
            isAuthorOfTheDeck: false,
            saveDecktext: "Save",
            playableDeck: false,
            zoomedCard: false,
            gridStyle: 1
        }

        if (props.deckId) {
            this.iniDeckInfo(props.deckId)
        } else {
            this.state.deckInfo = {
                artId: "5b483db6-b614-4af1-820a-42a4ee0c8707",
                author: "",
                authorUuid: "",
                deckList: "",
                name: "Deck name",
                intro: "",
                specialRules: "",
                lastModified: new Date(),
                public: true
            }
            this.state.isAuthorOfTheDeck = true
            this.state.showSocialPanel = false
        }

        //this.startWithDeckList("## Horde Deck ##\n1 XLN creature NO Burning Sun's Avatar 248d7759-aa73-4430-aa86-d45dc607944b 146c0cab-5f6b-425d-9f50-33d8da266235\n2 TOR sorcery NO Cleansing Meditation 658bccf8-fe73-4d6a-b37b-7a58034e5e5d fd6609ef-71af-4775-affc-34153700c556\n## Too Strong ##\n1 XLN creature NO Burning Sun's Avatar 248d7759-aa73-4430-aa86-d45dc607944b 146c0cab-5f6b-425d-9f50-33d8da266235\n## Available Tokens ##\n1 RIX token NO Polyraptor e47e3a40-f51f-41ed-8e7c-06200a2abc22 f8965a3a-93fe-4021-a665-b6013bdc86f7\n## Weak Permanents ##\n1 C19 enchantment NO Ghostly Prison e828b189-0e8f-43b8-b909-4c23e742e028 daeca212-3a70-470e-a934-9cd7e0ebf7eb\n## Powerfull Permanents ##\n1 RTR enchantment NO Collective Blessing 7f7049e8-49ed-46da-89f7-1e40aefb3b0c 53c84c4d-e6d6-4eac-9d14-5b6cba914c3d")
        //this.changeSelectedDeckTo(1)
        this.state.newSelectedDeck = 1

        let preferedGridStyle = localStorage.getItem('preferedGridStyle')
        if (preferedGridStyle) {
            this.state.gridStyle = preferedGridStyle
        }
    }

    iniDeckInfo = async (deckId) => {
        this.state.showInit = false

        await getDoc(doc(db, "decks", deckId))
            .then((querySnapshot) => {
                if (querySnapshot.exists()) {
                    let deckInfo = querySnapshot.data()
                    console.log(deckInfo)
                    this.setState({ deckInfo: deckInfo })
                    this.setState({ deckId: deckId })
                    this.startWithDeckList(deckInfo.deckList)

                    onAuthStateChanged(auth, (user) => {
                        if (user && deckInfo.authorUuid == user.uid) {
                            this.setState({ showSocialPanel: false })
                            this.setState({ isAuthorOfTheDeck: true })
                            this.setState({ zoomedCard: false })
                        }
                    });
                } else {
                    // docSnap.data() will be undefined in this case
                    console.log("No such document!");
                    this.setState({ deckId: "NONE" })
                }
            })
    }

    changeSelectedDeckTo = (newSelectedDeck) => {
        if (newSelectedDeck == 1) {
            this.setState({ deckSelectionInfo: "Spells and tokens in the Horde library" })
        } else if (newSelectedDeck == 2) {
            this.setState({ deckSelectionInfo: "Cards that are too strong to be drawn during the first turns (if player is using the option)" })
        } else if (newSelectedDeck == 3) {
            this.setState({ deckSelectionInfo: "Tokens/spells that the horde could have to create/cast during the game" })
        } else if (newSelectedDeck == 4) {
            this.setState({ deckSelectionInfo: "Permanents the Horde will start the game with" })
        } else if (newSelectedDeck == 5) {
            this.setState({ deckSelectionInfo: "Secondary deck the Horde will be able to cast from (milestones)" })
        }

        this.setState({ selectedDeckList: newSelectedDeck })

        //updateCardCountForSelectedDeck()
    }

    startWithOutsideDeckList = async (deckList) => {
        if (this.state.TMP_IsImportingDecklist) {
            return
        }
        this.setState({ TMP_IsImportingDecklist: true })
        let lines = deckList.split('\n')
        let counts = []
        let identifiers = []
        lines.forEach(line => {
            const elements = line.split(' ')
            if (elements.length >= 2) {
                const cardCount = elements[0].replace(/\D/g, '');
                const cardName = elements.slice(1, elements.length).join(' ')
                counts.push(cardCount)
                identifiers.push({ name: cardName })
            }
        })

        const batches = [];
        const numberOfCards = identifiers.length
        while (identifiers.length) {
            batches.push(identifiers.splice(0, 75));
        }

        Promise.all(batches.map(batch => {
            return new Promise((resolve, reject) => {

                // Scryfall API call
                const url = 'https://api.scryfall.com/cards/collection'
                fetch(url, {
                    method: "POST",
                    body: JSON.stringify({
                        identifiers: batch
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                }).then((response) => {
                    const results = response.json();
                    resolve(results)
                }).catch((error) => {
                    reject(error);
                });
            });
        }))
            .then(async identifersArray => {
                let allIdentifiersData = []
                let allIdentifiersNotFound = []

                identifersArray.forEach(array => {
                    allIdentifiersData = allIdentifiersData.concat(array.data)
                    allIdentifiersNotFound = allIdentifiersNotFound.concat(array.not_found)
                })

                let allIdentifiers = {
                    data: allIdentifiersData,
                    not_found: allIdentifiersNotFound
                }
                console.log(allIdentifiers)
                console.log(allIdentifiersNotFound)

                // Stop if error 
                if (allIdentifiersNotFound.length > 0) {
                    let errorMessage = "Error, cannot find :\n"
                    allIdentifiersNotFound.forEach((card) => {
                        errorMessage += "- " + card.name + "\n"
                    })
                    this.setState({ TMP_ImportErrorMessage: errorMessage })
                    this.setState({ TMP_IsImportingDecklist: false })
                    return
                }

                // Adding cards to the decklist
                this.state.selectedDeckList = 1

                const typeLineToType = function (typeLine) {
                    if (typeLine.includes("oken")) {
                        return "token"
                    } else if (typeLine.includes("reature")) {
                        return "creature"
                    } else if (typeLine.includes("nchantment")) {
                        return "enchantment"
                    } else if (typeLine.includes("rtifact")) {
                        return "artifact"
                    } else if (typeLine.includes("orcery")) {
                        return "sorcery"
                    } else if (typeLine.includes("nstant")) {
                        return "instant"
                    }
                    return "enchantment"
                }

                var n = 0
                for (let i = 0; i < numberOfCards; i++) {
                    let result = allIdentifiers.data[n]

                    let hasCardBeenFound = true
                    allIdentifiers.not_found.forEach(card => {
                        if (card.name == identifiers[i].name) {
                            hasCardBeenFound = false
                        }
                    })

                    if (hasCardBeenFound) {
                        const keywords = result.keywords.join().toLowerCase()
                        const cardCapacities = {
                            castFromGraveyard: ["flashback", "aftermath"].some(el => keywords.includes(el)),
                            castFromGraveyardThenExile: ["flashback", "aftermath"].some(el => keywords.includes(el)),
                            cantAttackOrBlock: ["defender"].some(el => keywords.includes(el))
                        }

                        let tmpCard = this.newCard(parseInt(counts[i]), result.id, typeLineToType(result.type_line.toLowerCase()), cardCapacities, result.oracle_id, result.set, result.name)
                        /*
                                                if (result.cmc >= 5) {
                                                    this.state.lategame.push(tmpCard)
                                                }*/

                        if (tmpCard.type == "creature") {
                            let tmp = [...this.state.deckList_creatures]
                            tmp.push(tmpCard)
                            this.state.deckList_creatures = this.regroupSameCardOnArray(tmp)
                        }
                        else if (tmpCard.type == "token") {
                            let tmp = [...this.state.deckList_tokens]
                            tmp.push(tmpCard)
                            this.state.deckList_tokens = this.regroupSameCardOnArray(tmp)
                        }
                        else if (tmpCard.type == "instant" || tmpCard.type == "sorcery") {
                            let tmp = [...this.state.deckList_instantsAndSorceries]
                            tmp.push(tmpCard)
                            this.state.deckList_instantsAndSorceries = this.regroupSameCardOnArray(tmp)
                        }
                        else {
                            let tmp = [...this.state.deckList_artifactsAndEnchantments]
                            tmp.push(tmpCard)
                            this.state.deckList_artifactsAndEnchantments = this.regroupSameCardOnArray(tmp)
                        }
                        n += 1
                    } else {
                        console.log(identifiers)
                    }
                }

                this.setState({ showInit: false })
            })
            .catch(error => {
                // En cas d'erreur lors de l'un des appels
                console.error(error);
            });
    }

    startWithDeckList = (deckList) => {
        let lines = deckList.split('\n')
        lines.forEach(line => {
            if (line.includes("## Horde Deck ##")) {
                this.state.selectedDeckList = 1
            } else if (line.includes("## Too Strong ##")) {
                this.state.selectedDeckList = 2
            } else if (line.includes("## Available Tokens ##")) {
                this.state.selectedDeckList = 3
            } else if (line.includes("## Weak Permanents ##")) {
                this.state.selectedDeckList = 4
            } else if (line.includes("## Powerfull Permanents ##")) {
                this.state.selectedDeckList = 5
            } else {
                if (line != "" && line != undefined) {
                    let elements = line.split(' ')

                    let cardFlashback = false
                    let cardFlashbackThenExile = false
                    let cardDefender = false
                    // If old or new system
                    if (elements[3] == "YES" || elements[3] == "NO") {
                        cardFlashback = elements[3] == "YES"
                        cardFlashbackThenExile = true
                    } else {
                        cardFlashback = elements[3].includes('f')
                        cardFlashbackThenExile = !elements[3].includes('e')
                        cardDefender = elements[3].includes('d')
                    }
                    const cardCapacities = {
                        castFromGraveyard: cardFlashback,
                        castFromGraveyardThenExile: cardFlashbackThenExile,
                        cantAttackOrBlock: cardDefender
                    }

                    let tmpCard = this.newCard(parseInt(elements[0]), elements[elements.length - 1], elements[2], cardCapacities, elements[elements.length - 2], elements[1], elements.slice(4, elements.length - 2).join(' '))

                    if (this.state.selectedDeckList == 1) {
                        if (tmpCard.type == "creature") {
                            let tmp = [...this.state.deckList_creatures]
                            tmp.push(tmpCard)
                            this.state.deckList_creatures = this.regroupSameCardOnArray(tmp)
                        }
                        else if (tmpCard.type == "token") {
                            let tmp = [...this.state.deckList_tokens]
                            tmp.push(tmpCard)
                            this.state.deckList_tokens = this.regroupSameCardOnArray(tmp)
                        }
                        else if (tmpCard.type == "instant" || tmpCard.type == "sorcery") {
                            let tmp = [...this.state.deckList_instantsAndSorceries]
                            tmp.push(tmpCard)
                            this.state.deckList_instantsAndSorceries = this.regroupSameCardOnArray(tmp)
                        }
                        else {
                            let tmp = [...this.state.deckList_artifactsAndEnchantments]
                            tmp.push(tmpCard)
                            this.state.deckList_artifactsAndEnchantments = this.regroupSameCardOnArray(tmp)
                        }
                    } else if (this.state.selectedDeckList == 2) {
                        let index = this.indexOfCard(tmpCard, this.state.lategame)
                        if (index >= 0) {
                            this.state.lategame.splice(index, 1)
                        } else {
                            this.state.lategame.push(tmpCard)
                        }
                        this.state.lategame = this.state.lategame
                    } else if (this.state.selectedDeckList == 3) {
                        // We can't add a token that is already in the horde decklist, this array is just for the tokens spawned by horde's spells
                        if (this.indexOfCard(tmpCard, this.state.deckList_tokens) >= 0) {
                            return
                        }
                        let tmp = [...this.state.tokens]
                        tmp.push(tmpCard)
                        this.state.tokens = this.regroupSameCardOnArray(tmp)
                    } else if (this.state.selectedDeckList == 4) {
                        let tmp = [...this.state.weak]
                        tmp.push(tmpCard)
                        this.state.weak = this.regroupSameCardOnArray(tmp)
                    } else if (this.state.selectedDeckList == 5) {
                        let tmp = [...this.state.powerful]
                        tmp.push(tmpCard)
                        this.state.powerful = this.regroupSameCardOnArray(tmp)
                    }
                }
            }
        })

        this.state.selectedDeckList = 1

        if (!this.state.isAuthorOfTheDeck) {
            if (this.state.deckList_creatures.length > 0) {
                this.state.zoomedCard = this.state.deckList_creatures[0]
            } else if (this.state.deckList_artifactsAndEnchantments.length > 0) {
                this.state.zoomedCard = this.state.deckList_artifactsAndEnchantments[0]
            } else if (this.state.deckList_instantsAndSorceries.length > 0) {
                this.state.zoomedCard = this.state.deckList_instantsAndSorceries[0]
            }
        }
    }

    newSearchResult = (name, type, capacities, set, oracleId, id, cost) => {
        this.state.searchResults.push({
            cardName: name,
            type: type,
            capacities: capacities,
            set: set,
            oracleId: oracleId,
            id: id,
            cost: cost
        })
    }

    newCard = (cardCount, cardId, cardType, capacities, oracleId, set, cardName) => {
        return {
            count: cardCount,
            id: cardId,
            type: cardType,
            capacities: {
                castFromGraveyard: capacities.castFromGraveyard,
                castFromGraveyardThenExile: capacities.castFromGraveyardThenExile,
                cantAttackOrBlock: capacities.cantAttackOrBlock
            },
            oracleId: oracleId,
            set: set,
            cardName: cardName
        }
    }

    regroupSameCardOnArray(array) {
        var tmpArray = [...array]
        var i = 0

        while (i < tmpArray.length) {
            var j = i + 1
            while (j < tmpArray.length) {
                if (tmpArray[i].id == tmpArray[j].id && tmpArray[j].count > 0/* && tmpArray[i].counters == 0 && tmpArray[j].counters == 0*/) {
                    tmpArray[i].count += tmpArray[j].count
                    tmpArray[j].count = 0
                }

                j += 1
            }
            i += 1
        }

        let compare = function compare(a, b) {
            if (a.cardName + "" + a.id < b.cardName + "" + b.id) {
                return -1;
            }
            if (a.cardName + "" + a.id > b.cardName + "" + b.id) {
                return 1;
            }
            return 0;
        }


        for (var i = 0; i < tmpArray.length; i++) {
            if (tmpArray[i].count == 0) {
                tmpArray.splice(i, 1)
                i -= 1
            }
        }
        tmpArray.sort(compare)
        return tmpArray
    }

    recreateCard = (card) => {
        return { ...card }
        //return this.newCard(card.count, card.id, card.type, card.capacities, card.oracleId, card.set, card.cardName)
    }

    changeCardToShow = (newCard) => {
        let card = this.recreateCard(newCard)
        this.setState({ cardToShow: card })
        this.setState({ zoomedCard: false })
        this.setState({ showSocialPanel: false })

        if (newCard.oracleId == this.state.cardToShow.oracleId) {
            return
        }

        this.setState({ reprints: [] })

        if (card.id.includes("https://i.imgur.com") || card.id.includes("D::")) {
            this.setState({ reprints: [card] })
            return
        }

        let scryfallPrintsApi = "https://api.scryfall.com/cards/search?order=released&q=oracleid%3A" + card.oracleId + "&unique=prints"
        console.log(scryfallPrintsApi)

        fetch(scryfallPrintsApi)
            .then(res => res.json())
            .then(results => {
                console.log(results.data)

                let tmpArray = []

                results.data.forEach(result => {
                    let tmpCard = this.recreateCard(card)
                    tmpCard.id = result.id
                    tmpCard.capacities = {}
                    tmpArray.push(tmpCard)
                });
                this.setState({ reprints: tmpArray })
            })
            .catch(err => { throw err });
    }

    changeCardType = (newType) => {
        console.log(newType)
        let card = this.recreateCard(this.state.cardToShow)
        card.type = newType
        this.updateCardInSelectedDeck(card)
        this.changeCardToShow(card)
    }

    changeCardHasFlashback = (changeThenExile = false) => {
        let card = this.recreateCard(this.state.cardToShow)
        if (changeThenExile) {
            card.capacities = {
                castFromGraveyard: card.capacities.castFromGraveyard,
                castFromGraveyardThenExile: !card.capacities.castFromGraveyardThenExile,
                cantAttackOrBlock: card.capacities.cantAttackOrBlock
            }
        } else {
            card.capacities = {
                castFromGraveyard: !card.capacities.castFromGraveyard,
                castFromGraveyardThenExile: card.capacities.castFromGraveyardThenExile,
                cantAttackOrBlock: card.capacities.cantAttackOrBlock
            }
        }
        this.updateCardInSelectedDeck(card)
        this.changeCardToShow(card)
    }

    changeCardCantAttackOrBlock = () => {
        let card = this.recreateCard(this.state.cardToShow)
        card.capacities = {
            castFromGraveyard: card.capacities.castFromGraveyard,
            castFromGraveyardThenExile: card.capacities.castFromGraveyardThenExile,
            cantAttackOrBlock: !card.capacities.cantAttackOrBlock
        }
        this.updateCardInSelectedDeck(card)
        this.changeCardToShow(card)
    }

    changeSearchForTokens = () => {
        this.setState({ onlySearchTokens: !this.state.onlySearchTokens })
    }

    resultClicked = (result) => {
        let tmp = this.recreateCard(result)
        tmp.count = 1
        this.changeCardToShow(tmp)
    }

    searchForCards = (event) => {
        event.preventDefault();
        let searchText = document.getElementById("SearchField").value
        if (searchText == "") {
            return
        }

        this.setState({ searchResults: [] })
        this.setState({ searchInfoText: "Searching ..." })

        let scryfallSearchApi = "https://api.scryfall.com/cards/search?q=" + searchText.replace(" ", "+") + (this.state.onlySearchTokens ? "+type%3Atoken" : "")
        console.log(scryfallSearchApi)

        fetch(scryfallSearchApi)
            .then(res => res.json())
            .then(results => {
                const typeLineToType = function (typeLine) {
                    if (typeLine.includes("oken")) {
                        return "token"
                    } else if (typeLine.includes("reature")) {
                        return "creature"
                    } else if (typeLine.includes("nchantment")) {
                        return "enchantment"
                    } else if (typeLine.includes("rtifact")) {
                        return "artifact"
                    } else if (typeLine.includes("orcery")) {
                        return "sorcery"
                    } else if (typeLine.includes("nstant")) {
                        return "instant"
                    }
                    return "enchantment"
                }

                this.state.searchResults = []
                console.log(results)
                if (results.status == 404) {
                    this.setState({ searchInfoText: "No cards found" })
                } else {
                    this.setState({ searchInfoText: "" })
                }

                results.data.forEach(result => {
                    let manaCost = result.mana_cost
                    if (manaCost != "" && manaCost != undefined) {
                        manaCost = manaCost.split(' // ')[0]
                        manaCost = manaCost.replaceAll('/', '_')
                        manaCost = manaCost.substring(1, manaCost.length - 1).split("}{")
                    }
                    const keywords = result.keywords.join().toLowerCase()

                    const cardCapacities = {
                        castFromGraveyard: ["flashback", "aftermath", "unearth", "disturb"].some(el => keywords.includes(el)),
                        castFromGraveyardThenExile: ["flashback", "aftermath"].some(el => keywords.includes(el)),
                        cantAttackOrBlock: ["defender"].some(el => keywords.includes(el))
                    }

                    // Remove // if not DFC
                    let name = result.name
                    if (keywords.includes("aftermath")) {
                        name = name.split(' // ')[0]
                    }

                    console.log(cardCapacities)
                    this.newSearchResult(name, typeLineToType(result.type_line.toLowerCase()), cardCapacities, result.set, result.oracle_id, result.id, manaCost)
                });

                this.setState({ searchResults: this.state.searchResults })
            })
            .catch(err => { throw err });
    }

    cardOnDeckListClicked = (card) => {
        if (!this.state.isAuthorOfTheDeck) {
            return
        }
        let tmpCard = this.recreateCard(card)
        tmpCard.count = 1

        if (this.state.selectedDeckList != 2) {
            this.changeCardToShow(tmpCard)
        } else {
            let index = -1
            for (var i = 0; i < this.state.lategame.length; i++) {
                if (this.state.lategame[i].id == tmpCard.id) {
                    index = i
                }
            }
            let tmpArray = [...this.state.lategame]
            if (index >= 0) {
                tmpArray.splice(index, 1)
            } else {
                tmpArray.push(tmpCard)
            }
            this.setState({ lategame: tmpArray })
        }
    }

    indexOfCard = (card, array) => {
        for (var i = 0; i < array.length; i++) {
            if (card.id == array[i].id) {
                return i
            }
        }
        return -1
    }

    updateCardInSelectedDeck = (card) => {
        let oldCard = false
        let index = -1

        if (this.state.selectedDeckList == 1) {
            let tmp = [...this.state.deckList_creatures]
            index = this.indexOfCard(card, tmp)
            if (index >= 0) {
                oldCard = this.recreateCard(tmp[index])
                tmp.splice(index, 1)
                this.state.deckList_creatures.splice(index, 1)
            }

            tmp = [...this.state.deckList_tokens]
            index = this.indexOfCard(card, tmp)
            if (index >= 0) {
                oldCard = this.recreateCard(tmp[index])
                tmp.splice(index, 1)
                this.state.deckList_tokens.splice(index, 1)
            }

            tmp = [...this.state.deckList_instantsAndSorceries]
            index = this.indexOfCard(card, tmp)
            if (index >= 0) {
                oldCard = tmp[index]
                this.state.deckList_instantsAndSorceries.splice(index, 1)
            }

            tmp = [...this.state.deckList_artifactsAndEnchantments]
            index = this.indexOfCard(card, tmp)
            if (index >= 0) {
                oldCard = tmp[index]
                this.state.deckList_artifactsAndEnchantments.splice(index, 1)
            }
        } else if (this.state.selectedDeckList == 2) {
            let tmp = [...this.state.lategame]
            let index = this.indexOfCard(card, tmp)
            if (index >= 0) {
                oldCard = tmp[index]
                this.state.lategame.splice(index, 1)
            }
        } else if (this.state.selectedDeckList == 3) {
            let tmp = [...this.state.tokens]
            let index = this.indexOfCard(card, tmp)
            if (index >= 0) {
                oldCard = tmp[index]
                this.state.tokens.splice(index, 1)
            }
        } else if (this.state.selectedDeckList == 4) {
            let tmp = [...this.state.weak]
            let index = this.indexOfCard(card, tmp)
            if (index >= 0) {
                oldCard = tmp[index]
                this.state.weak.splice(index, 1)
            }
        } else if (this.state.selectedDeckList == 5) {
            let tmp = [...this.state.powerful]
            let index = this.indexOfCard(card, tmp)
            if (index >= 0) {
                oldCard = tmp[index]
                this.state.powerful.splice(index, 1)
            }
        }

        if (oldCard) {
            let tmpCard = this.newCard(oldCard.count, oldCard.id, card.type, card.capacities, oldCard.oracleId, oldCard.set, oldCard.cardName)
            this.addCardToSelectedDeck(tmpCard, oldCard.count)
        }

    }

    removeOneCardFromArray = (index, array) => {
        if (index < 0) {
            return array
        }
        let tmpArray = [...array]

        tmpArray[index].count -= 1
        if (tmpArray[index].count <= 0) {
            tmpArray.splice(index, 1)
        }

        return tmpArray
    }

    removeCardFromSelectedDeck = (card) => {
        if (this.state.selectedDeckList == 1) {
            if (card.type == "creature") {
                let tmp = [...this.state.deckList_creatures]
                let index = this.indexOfCard(card, tmp)
                tmp = this.removeOneCardFromArray(index, tmp)
                this.setState({ deckList_creatures: this.regroupSameCardOnArray(tmp) })
            }
            else if (card.type == "token") {
                let tmp = [...this.state.deckList_tokens]
                let index = this.indexOfCard(card, tmp)
                tmp = this.removeOneCardFromArray(index, tmp)
                this.setState({ deckList_tokens: this.regroupSameCardOnArray(tmp) })
            }
            else if (card.type == "instant" || card.type == "sorcery") {
                let tmp = [...this.state.deckList_instantsAndSorceries]
                let index = this.indexOfCard(card, tmp)
                tmp = this.removeOneCardFromArray(index, tmp)
                this.setState({ deckList_instantsAndSorceries: this.regroupSameCardOnArray(tmp) })
            }
            else {
                let tmp = [...this.state.deckList_artifactsAndEnchantments]
                let index = this.indexOfCard(card, tmp)
                tmp = this.removeOneCardFromArray(index, tmp)
                this.setState({ deckList_artifactsAndEnchantments: this.regroupSameCardOnArray(tmp) })
            }
            let tmp = [...this.state.lategame]
            let index = this.indexOfCard(card, tmp)
            tmp = this.removeOneCardFromArray(index, tmp)
            this.setState({ lategame: this.regroupSameCardOnArray(tmp) })
        } else if (this.state.selectedDeckList == 3) {
            let tmp = [...this.state.tokens]
            let index = this.indexOfCard(card, tmp)
            tmp = this.removeOneCardFromArray(index, tmp)
            this.setState({ tokens: this.regroupSameCardOnArray(tmp) })
        } else if (this.state.selectedDeckList == 4) {
            let tmp = [...this.state.weak]
            let index = this.indexOfCard(card, tmp)
            tmp = this.removeOneCardFromArray(index, tmp)
            this.setState({ weak: this.regroupSameCardOnArray(tmp) })
        } else if (this.state.selectedDeckList == 5) {
            let tmp = [...this.state.powerful]
            let index = this.indexOfCard(card, tmp)
            tmp = this.removeOneCardFromArray(index, tmp)
            this.setState({ powerful: this.regroupSameCardOnArray(tmp) })
        }

        //updateCardCountForSelectedDeck()
        //showSaveButton = true
    }

    addCardToSelectedDeck = (card, count = 1) => {
        let tmpCard = this.recreateCard(card)
        tmpCard.count = count
        //tmpCard.cardCount = 1

        if (this.state.selectedDeckList == 1) {
            if (card.type == "creature") {
                let tmp = [...this.state.deckList_creatures]
                tmp.push(tmpCard)
                this.setState({ deckList_creatures: this.regroupSameCardOnArray(tmp) })
            }
            else if (card.type == "token") {
                let tmp = [...this.state.deckList_tokens]
                tmp.push(tmpCard)
                this.setState({ deckList_tokens: this.regroupSameCardOnArray(tmp) })
            }
            else if (card.type == "instant" || card.type == "sorcery") {
                let tmp = [...this.state.deckList_instantsAndSorceries]
                tmp.push(tmpCard)
                this.setState({ deckList_instantsAndSorceries: this.regroupSameCardOnArray(tmp) })
            }
            else {
                let tmp = [...this.state.deckList_artifactsAndEnchantments]
                tmp.push(tmpCard)
                this.setState({ deckList_artifactsAndEnchantments: this.regroupSameCardOnArray(tmp) })
            }
        } else if (this.state.selectedDeckList == 2) {
            let index = this.indexOfCard(card, this.state.lategame)
            if (index >= 0) {
                this.state.lategame.splice(index, 1)
            }
            tmpCard.count = 1
            this.state.lategame.push(tmpCard)
            this.setState({ lategame: this.state.lategame })
        } else if (this.state.selectedDeckList == 3) {
            // We can't add a token that is already in the horde decklist, this array is just for the tokens spawned by horde's spells
            if (this.indexOfCard(card, this.state.deckList_tokens) >= 0) {
                return
            }
            let tmp = [...this.state.tokens]
            tmp.push(tmpCard)
            this.setState({ tokens: this.regroupSameCardOnArray(tmp) })
        } else if (this.state.selectedDeckList == 4) {
            let tmp = [...this.state.weak]
            tmp.push(tmpCard)
            this.setState({ weak: this.regroupSameCardOnArray(tmp) })
        } else if (this.state.selectedDeckList == 5) {
            let tmp = [...this.state.powerful]
            tmp.push(tmpCard)
            console.log(this.regroupSameCardOnArray(tmp))
            this.setState({ powerful: this.regroupSameCardOnArray(tmp) })
        }
        // updateCardCountForSelectedDeck()
        //showSaveButton = true
    }

    pickNewReprint = (reprint) => {
        let newReprint = this.recreateCard(this.state.cardToShow)
        newReprint.id = reprint.id
        console.log(newReprint)
        this.setState({ cardToShow: newReprint })
        this.setState({ showReprintPicker: false })
    }

    getDecklistData = (getHordeFormat) => {
        if (getHordeFormat) {
            const cardString = function (card) {
                return card.count + " " + card.set.toUpperCase() + " " + card.type + " -" + (card.capacities.castFromGraveyard ? "f" : "") + (card.capacities.castFromGraveyardThenExile ? "" : "e") + (card.capacities.cantAttackOrBlock ? "d" : "") + " " + card.cardName + " " + card.oracleId + " " + card.id + "\n"
            }

            let deckData = ""
            deckData += "## Horde Deck ##\n"
            this.state.deckList_creatures.forEach(card => {
                deckData += cardString(card)
            })
            deckData += "\n"
            this.state.deckList_artifactsAndEnchantments.forEach(card => {
                deckData += cardString(card)
            })
            deckData += "\n"
            this.state.deckList_instantsAndSorceries.forEach(card => {
                deckData += cardString(card)
            })
            deckData += "\n"
            this.state.deckList_tokens.forEach(card => {
                deckData += cardString(card)
            })
            deckData += "\n"
            deckData += "## Too Strong ##\n"
            this.state.lategame.forEach(card => {
                deckData += cardString(card)
            })
            deckData += "\n"
            deckData += "## Available Tokens ##\n"
            this.state.tokens.forEach(card => {
                deckData += cardString(card)
            })
            deckData += "\n"
            deckData += "## Weak Permanents ##\n"
            this.state.weak.forEach(card => {
                deckData += cardString(card)
            })
            deckData += "\n"
            deckData += "## Powerfull Permanents ##\n"
            this.state.powerful.forEach(card => {
                deckData += cardString(card)
            })

            return deckData
        } else {
            const cardString = function (card) {
                return card.count + " " + card.cardName + " (" + card.set.toUpperCase() + ")" + "\n"
            }

            let deckData = ""
            this.state.deckList_creatures.forEach(card => {
                deckData += cardString(card)
            })
            deckData += "\n"
            this.state.deckList_artifactsAndEnchantments.forEach(card => {
                deckData += cardString(card)
            })
            deckData += "\n"
            this.state.deckList_instantsAndSorceries.forEach(card => {
                deckData += cardString(card)
            })
            deckData += "\n"
            this.state.deckList_tokens.forEach(card => {
                deckData += cardString(card)
            })
            deckData += "\n"

            if (this.state.tokens.length > 0) {
                deckData += "Additional tokens\n"
                this.state.tokens.forEach(card => {
                    deckData += cardString(card)
                })
                deckData += "\n"
            }

            if (this.state.weak.length > 0) {
                deckData += "Start\n"
                this.state.weak.forEach(card => {
                    deckData += cardString(card)
                })
                deckData += "\n"
            }

            if (this.state.powerful.length > 0) {
                deckData += "Milestones\n"
                this.state.powerful.forEach(card => {
                    deckData += cardString(card)
                })
                deckData += "\n"
            }

            return deckData
        }
    }

    saveDeck = async () => {
        if (auth.currentUser == null) {
            console.log("Only logged in users can save deck")
            return
        }

        document.getElementById("Save-button").disabled = true;

        const deckData = this.getDecklistData(true)
        this.state.deckInfo.deckList = deckData

        // Check if deck already exist, we save changes
        var docRef = doc(db, "decks", "" + this.state.deckId)

        const resetSaveText = () => {
            this.setState({ saveDecktext: "Save" })
        }

        await getDoc(docRef).then(async (docu) => {
            var deck = this.state.deckInfo
            var deckObject = {
                artId: deck.artId,
                author: auth.currentUser.displayName,
                authorUuid: auth.currentUser.uid,
                deckList: deckData,
                name: deck.name,
                intro: deck.intro,
                specialRules: deck.specialRules,
                lastModified: new Date(),
                nameKeywords: deck.name.toLowerCase().split(' ').filter(function (e) { return e !== '' && e.length > 1 }),
                public: deck.public
            }
            // Only add likes if there is any, else keep it undefined
            if (deck.likes != undefined) {
                var deckObject = {
                    artId: deck.artId,
                    author: auth.currentUser.displayName,
                    authorUuid: auth.currentUser.uid,
                    deckList: deckData,
                    name: deck.name,
                    intro: deck.intro,
                    specialRules: deck.specialRules,
                    lastModified: new Date(),
                    nameKeywords: deck.name.toLowerCase().split(' ').filter(function (e) { return e !== '' && e.length > 1 }),
                    public: deck.public,
                    likes: deck.likes,
                    likesCount: deck.likesCount
                }
            }
            // If exist, save changes
            if (docu.exists()) {
                try {
                    const deckRef = await setDoc(doc(db, "decks", this.state.deckId), deckObject);

                    this.setState({ saveDecktext: "Saved" })

                    const userData = JSON.parse(sessionStorage.getItem("userData"))
                    sessionStorage.setItem("userData", JSON.stringify({
                        uid: userData.uid,
                        decks: userData.decksData,
                        shouldUpdateDecks: true,
                        userCollection: userData.userCollection
                    }))

                    setTimeout(resetSaveText, 5000);
                } catch (e) {
                    console.error("Error adding document: ", e);
                    document.getElementById("Save-button").disabled = false;
                    this.setState({ saveDecktext: "Error" })
                }
            } else {
                // Else Create new deck
                try {
                    const deckRef = await addDoc(collection(db, "decks"), deckObject);
                    console.log("Document written with ID: ", deckRef.id);
                    this.setState({ deckId: deckRef.id })
                    window.history.pushState({}, null, '/Deck/' + deckRef.id)

                    this.addDeck(deckRef.id)

                    this.setState({ saveDecktext: "Saved !" })

                    const userData = JSON.parse(sessionStorage.getItem("userData"))
                    let decks = userData.decks
                    let deckCollection = userData.userCollection
                    decks.push(deckObject)
                    deckCollection.push(deckRef.id)

                    sessionStorage.setItem("userData", JSON.stringify({
                        uid: userData.uid,
                        decks: userData.decksData,
                        shouldUpdateDecks: decks,
                        userCollection: deckCollection
                    }))

                    setTimeout(resetSaveText, 5000);
                } catch (e) {
                    console.error("Error adding document: ", e);
                }
            }
            document.getElementById("Save-button").disabled = false;
        }).catch((error) => {
            console.log("Error getting document:", error);
            document.getElementById("Save-button").disabled = false;
            this.setState({ saveDecktext: "Error" })
        });
    }

    updateIsCustomDeckListValid = () => {
        let deckList = document.getElementById("DeckList-init-text").value
        this.setState({ TMP_isDeckListValid: deckList.includes("## Horde Deck ##") && deckList.includes("## Too Strong ##") && deckList.includes("## Available Tokens ##") && deckList.includes("## Weak Permanents ##") && deckList.includes("## Powerfull Permanents ##") })
    }
    /*
        saveAsTxt = () => {
            var filename = document.getElementById("DeckName").value
            var text = this.state.TMP_Decklist
    
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', filename);
    
            element.style.display = 'none';
            document.body.appendChild(element);
    
            element.click();
    
            document.body.removeChild(element);
        }*/

    //------------------------------------------------ SOCIAL ------------------------------------------------

    toggleShowSocial = () => {
        this.setState({ showSocialPanel: !this.state.showSocialPanel })
    }

    addDeck = async (deckId) => {
        let id = deckId
        if (id == false) {
            id = this.state.deckId
        }
        const collectionRef = doc(db, "usersCollection", auth.currentUser.uid)
        await updateDoc(collectionRef, {
            collection: arrayUnion(id)
        }).then((doc) => {
            console.log("Deck successfully added")
            return true
        });

        const userData = JSON.parse(sessionStorage.getItem("userData"))
        if (userData != undefined && userData.userCollection != undefined) {
            let newCollection = userData.userCollection
            let newDecksData = userData.decks
            const elementExists = newCollection.includes(id)
            if (!elementExists) {
                newCollection.push(id);
                let newDeck = { ...this.state.deckInfo, deckId: id }
                newDecksData.push(newDeck)

                sessionStorage.setItem("userData", JSON.stringify({
                    uid: userData.uid,
                    decks: newDecksData,
                    shouldUpdateDecks: false,
                    userCollection: newCollection
                }))
            }
        } else {
            sessionStorage.setItem("userData", JSON.stringify({
                uid: userData.uid,
                decks: userData.decksData,
                shouldUpdateDecks: true,
                userCollection: userData.userCollection
            }))
        }
    }

    deckListCardKey = (card) => {
        return card.id + (card.capacities.castFromGraveyard ? "f" : "") + (card.capacities.castFromGraveyardThenExile ? "" : "e") + (card.capacities.cantAttackOrBlock ? "d" : "")
    }

    likeDeck = async () => {
        // Si le deck n'est pas initialisé
        if (this.state.deckInfo == false) { return }
        const userData = JSON.parse(sessionStorage.getItem("userData"))
        // Si l'utilisateur n'est pas connecté
        if (userData == undefined) { return }
        let userId = userData.uid
        // Si l'utilisateur est l'auteur du deck
        if (userId == this.state.deckInfo.authorUuid) { return }
        // Si l'utilisateur a déjà liké ce deck
        if (this.state.deckInfo.likes != undefined && this.state.deckInfo.likes.includes(userId)) { return }

        const collectionRef = doc(db, "decks", this.state.deckId)
        await updateDoc(collectionRef, {
            likes: arrayUnion(userId),
            likesCount: this.state.deckInfo.likes == undefined ? 1 : this.state.deckInfo.likes.length + 1
        }).then((doc) => {
            console.log("Like successfully added")
            return true
        });
    }


    deckInfoCardCount = (text, value) => {
        if (value == 0) {
            return <div></div>
        } else {
            return <p>{text + " (" + value + ")"}</p>
        }
    }

    selectDeck = (deck) => {
        this.setState({ playableDeck: deck })
    }

    setZoomedCard = (card) => {
        let c = { ...card }
        c.showFront = c.showFront === undefined ? false : c.showFront
        if (this.state.isAuthorOfTheDeck) {
            if (card && this.state.cardToShow.id != card.id) {
                this.setState({ zoomedCard: c })
            } else {
                this.setState({ zoomedCard: false })
            }
        } else if (card) {
            this.setState({ zoomedCard: c })
        }
    }


    //------------------------------------------------ RENDER ------------------------------------------------

    render() {
        if (this.state.showInit) {
            return (
                <div id="DeckEditor">
                    <HalfScreenPanel
                        imageName="img_intro.jpg"
                        copyright="Cristi Balanescu"
                        component={
                            <div class="deckEditor-init d-flex flex-column">
                                <h1>New deck</h1>
                                <Button text="Create new empty deck" clicked={() => {
                                    this.setState({ showInit: false })
                                }} />
                                <h2 class="mt-5">Or</h2>
                                <p>Import a deck by copying the decklist below and press 'Start using decklist'. Only use decklists in the MTG Online format "1 card name" or decklists made with this app.</p>

                                <textarea id="DeckList-init-text" class="blurred-background" name="story" cols="110" onChange={this.updateIsCustomDeckListValid}>

                                </textarea>
                                <p class="error-message">{this.state.TMP_ImportErrorMessage}</p>
                                <Button text="Start using decklist" classes={this.state.TMP_IsImportingDecklist ? " disabled" : ""} clicked={() => {
                                    if (this.state.TMP_isDeckListValid) {
                                        // Start using a ATH decklist
                                        let deckList = document.getElementById("DeckList-init-text").value
                                        this.startWithDeckList(deckList)
                                        this.setState({ showInit: false })
                                    } else {
                                        // Start using an outside decklist
                                        let deckList = document.getElementById("DeckList-init-text").value
                                        this.startWithOutsideDeckList(deckList)
                                    }
                                }} />

                            </div>
                        }
                        hidePanel="/yourdecks"
                    />
                </div>
            )
        } else if (!this.state.showInit && !this.state.deckInfo) {
            if (this.state.deckId == "NONE") {
                return (<div id="DeckEditor"><p class="info-message text-white">This deck doesn't exist</p></div>)
            } else {
                return (<div id="DeckEditor"><p class="info-message text-white">Loading...</p></div>)
            }
        } else {
            return (
                <div>
                    {/*
<div class="fullscreen portrait-message">
                        <p>This app only works in landscape mode.</p>
                    </div>
            */}
                    <PlayablePanel selectedDeck={this.state.playableDeck} setSelectedDeck={this.selectDeck} component={
                        <div id="DeckEditor" class="d-flex flex-column">
                            <NewNavBar selected="Browser" />
                            <DeckIntro deckInfo={this.state.deckInfo} isAuthorOfTheDeck={this.state.isAuthorOfTheDeck} deckNameChanged={
                                (deckInfo) => {
                                    this.setState({ deckInfo: deckInfo })
                                }
                            } play={() => {
                                let deck = this.state.deckInfo
                                deck.deckList = this.getDecklistData(true)
                                // update decklist if editor mode
                                this.selectDeck(deck)
                            }} showArtPicker={() => {
                                if (this.state.deckList_artifactsAndEnchantments.length > 0 || this.state.deckList_creatures.length > 0 || this.state.deckList_instantsAndSorceries.length > 0 || this.state.deckList_tokens.length > 0) {
                                    this.setState({ showArtPicker: true })
                                }
                            }} getDeckList={() => {
                                this.state.TMP_Decklist = this.getDecklistData(true)
                                this.state.TMP_Decklist_Classic = this.getDecklistData(false)
                                this.setState({ showSavePanel: true });
                            }} saveDeck={() => { this.saveDeck() }}
                                saveDeckText={this.state.saveDecktext}
                                addDeck={this.addDeck}
                                deckId={this.state.deckId}
                                likeDeck={this.likeDeck}
                                onChangeIsPublic={() => {
                                    this.state.deckInfo.public = !this.state.deckInfo.public
                                }}
                                onChangeIntro={(event) => {
                                    const deckInfo = this.state.deckInfo
                                    deckInfo.intro = event.target.value
                                    this.setState({ deckInfo: deckInfo })
                                    //this.state.deckInfo.intro = event.target.value
                                }}
                                onChangeSpecialRules={(event) => {
                                    const deckInfo = this.state.deckInfo
                                    deckInfo.specialRules = event.target.value
                                    this.setState({ deckInfo: deckInfo })
                                }}
                            />
                            <div class="d-flex flex-row text-white">

                                <div class="side-panel landscape-only">
                                    {this.state.isAuthorOfTheDeck && (<div>
                                        <div class={"search-panel" + (this.state.cardToShow ? " invisible" : "")}>
                                            <div class="search-group">
                                                <div class="d-flex flex-row align-items-center justify-content-between">
                                                    {<button class={"default-button blurred-background ms-0 custom-card-toggle" + (!this.state.isAuthorOfTheDeck || this.state.cardToShow ? " invisible" : "")} onClick={() => { this.setState({ showCustomCardPanel: true }) }}>
                                                        Custom card
                                                    </button>}
                                                    <label>
                                                        <input type="radio" checked={this.state.onlySearchTokens} onClick={() => { this.changeSearchForTokens() }} />
                                                        Search for tokens
                                                    </label>
                                                </div>

                                                <InputGroup className='search-row blurred-background'>
                                                    <form onSubmit={this.searchForCards} >
                                                        <Form.Control
                                                            placeholder={this.state.onlySearchTokens ? 'Token name ...' : 'Card name ...'}
                                                            aria-label={this.state.onlySearchTokens ? 'Token name ...' : 'Card name ...'}
                                                            aria-describedby="card-searchField"
                                                            id="SearchField"
                                                        />
                                                        <input type="submit" variant="outline-secondary" id="button-addon2" className='' value="" />
                                                    </form>
                                                </InputGroup>
                                            </div>
                                            <div class="search-results blurred-background m-0 mt-2">
                                                <h3>{this.state.searchInfoText}</h3>
                                                {this.state.searchResults.map(function (result, i) {
                                                    return (
                                                        <SearchResult result={result} resultClicked={this.resultClicked} key={i} />
                                                    )
                                                }, this)}

                                            </div>
                                        </div>
                                        {
                                            this.state.cardToShow ?
                                                (<div class={"card-panel" + (this.state.selectedDeckList == 2 ? " unusable" : "")}>
                                                    <Button text="Return" clicked={() => { this.setState({ cardToShow: false }) }} classes=" return-button"></Button>
                                                    <Card_EditorPanel card={this.state.cardToShow} cardClicked={() => this.setState({ showReprintPicker: true })} setZoomedCard={this.setZoomedCard} />
                                                    <div class="add-row-extended mt-4">
                                                        <div>
                                                            <p>Press the card to pick another print</p>
                                                            <h1>{this.state.reprints.length > 0 ? (this.state.reprints.length + " reprints") : "loading reprints ..."}</h1>
                                                        </div>
                                                        <div class="add-row mt-2 mb-2">
                                                            <div class="d-flex flex-row blurred-background">
                                                                {this.state.cardToShow.type == "token" ? (
                                                                    <button onClick={() => {
                                                                        for (let i = 0; i < 5; i++) {
                                                                            this.removeCardFromSelectedDeck(this.state.cardToShow)
                                                                        }
                                                                    }}>-5</button>
                                                                ) : (<p></p>)

                                                                }
                                                                <button onClick={() => { this.removeCardFromSelectedDeck(this.state.cardToShow) }}>-</button>
                                                            </div>
                                                            <div class="d-flex flex-row blurred-background">
                                                                <button onClick={() => { this.addCardToSelectedDeck(this.state.cardToShow) }}>+</button>
                                                                {this.state.cardToShow.type == "token" ? (
                                                                    <button onClick={() => {
                                                                        this.addCardToSelectedDeck(this.state.cardToShow, 5)
                                                                    }}>+5</button>
                                                                ) : (<p></p>)

                                                                }

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="interaction">
                                                        <WithHelp component={
                                                            <label>
                                                                <input type="checkbox" checked={this.state.cardToShow.capacities.castFromGraveyard} onChange={() => { this.changeCardHasFlashback() }} />
                                                                Cast from graveyard
                                                            </label>
                                                        } help="If this card is in the graveyard, it will be cast the next time the Horde draws."></WithHelp>
                                                        {this.state.cardToShow.capacities.castFromGraveyard ? (<WithHelp component={
                                                            <label class="ps-4 ms-2">
                                                                <input type="checkbox" checked={this.state.cardToShow.capacities.castFromGraveyardThenExile} onChange={() => { this.changeCardHasFlashback(true) }} />
                                                                Then exile
                                                            </label>
                                                        } help="If enabled, the card will be exiled afterward (for flashback or permanents with an ability which doesn't make them come back to the battlefield)"></WithHelp>) : (<div class="m-1"></div>)}


                                                        {
                                                            this.state.cardToShow.type == "creature" || this.state.cardToShow.type == "token" ?
                                                                (
                                                                    <label>
                                                                        <input type="checkbox" checked={this.state.cardToShow.capacities.cantAttackOrBlock} onChange={() => { this.changeCardCantAttackOrBlock() }} />
                                                                        Can't attack or block
                                                                    </label>
                                                                ) : (<div></div>)
                                                        }

                                                        {/*
                                                                this.state.cardToShow.type == "creature" || this.state.cardToShow.type == "token" ?
                                                                    (
                                                                        <div>
                                                                            <h1>Change card type :</h1>
                                                                            <div>
                                                                                <label>
                                                                                    <input type="checkbox" checked={this.state.cardToShow.type == "creature"} onChange={() => { this.changeCardType("creature") }} />
                                                                                    Creature
                                                                                </label>
                                                                                <label>
                                                                                    <input type="checkbox" checked={this.state.cardToShow.type == "token"} onChange={() => { this.changeCardType("token") }} />
                                                                                    Token
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    ) : (<div></div>)
                                                                    */}

                                                    </div>
                                                    <Form.Select className="form-select card-type-select blurred-background" aria-label="Default select example"
                                                        onChange={(event) => {
                                                            this.changeCardType(event.target.value)
                                                        }}
                                                        value={
                                                            this.state.cardToShow.type
                                                        }>
                                                        <option value="creature">Creature</option>
                                                        <option value="token">Token</option>
                                                        <option value="enchantment">Enchantment</option>
                                                        <option value="artifact">Artifact</option>
                                                        <option value="sorcery">Sorcery</option>
                                                        <option value="instant">Insant</option>
                                                    </Form.Select>
                                                </div>)
                                                : (<div></div>)

                                        }
                                    </div>
                                    )}
                                    <ZoomedCard zoomedCard={this.state.zoomedCard} widthVh={50} />
                                </div>


                                <div class="main">
                                    <div class="topBar">
                                        {/*
<Button text="Get deck list" clicked={() => {
                                    this.state.TMP_Decklist = this.getDecklistData()
                                    this.setState({ showSavePanel: true });
                                }} id="Decklist-button" />
                    */}
                                        {/*
                                                        <div class="topBar-left-section">
                                    {
                                        this.state.isAuthorOfTheDeck ?
                                            (<input type="text" class="" id="DeckName" maxLength="25" defaultValue={this.state.deckInfo.name} onChange={(event) => {
                                                const deckInfo = this.state.deckInfo
                                                deckInfo.name = event.target.value
                                                this.setState({ deckInfo: deckInfo })
                                            }} />) :
                                            (<input type="text" class="" id="DeckName" defaultValue={this.state.deckInfo.name} onChange={(event) => {
                                                const deckInfo = this.state.deckInfo
                                                deckInfo.name = event.target.value
                                                this.setState({ deckInfo: deckInfo })
                                            }} readOnly />)
                                    }
                                    {this.state.isAuthorOfTheDeck ? (<button class={"default-button blurred-background deck-info-toggle purpleOutline" + (this.state.showSocialPanel ? " unselected" : "")} onClick={this.toggleShowSocial}>
                                        <img src={SocialIcon} />
                                    </button>) : (<div></div>)}
                                    {
                                        auth.currentUser != null && (this.state.deckInfo.authorUuid == auth.currentUser.uid || this.state.deckInfo.authorUuid == "") ?
                                            (<Button text={this.state.saveDecktext} clicked={() => { this.saveDeck(); }} id="Save-button" classes=" purpleOutline" />) : (<div></div>)
                                    }
                                </div>
                */}



                                        {/*
                                <Button text="Menu" clicked={() => {
                                    window.location.href = '/'
                                }} classes=" exit-button" />
                                */}

                                        <div class="row1 row m-0">
                                            <div class="col-sm-6 p-0">
                                                <button onClick={() => { this.changeSelectedDeckTo(1) }} class={"col-6 " + (this.state.selectedDeckList == 1 ? "selected" : "")}>
                                                    Horde deck
                                                </button>
                                                <button onClick={() => { this.changeSelectedDeckTo(2) }} class={"col-6 " + (this.state.selectedDeckList == 2 ? "selected" : "")}>
                                                    Lategame cards
                                                </button>
                                            </div>
                                            <div class="col-sm-6 p-0">
                                                <button onClick={() => { this.changeSelectedDeckTo(3) }} class={"col-5 " + (this.state.selectedDeckList == 3 ? "selected" : "")}>
                                                    Tokens and spells available
                                                </button>

                                                <button onClick={() => { this.changeSelectedDeckTo(4) }} class={"col-3 " + (this.state.selectedDeckList == 4 ? "selected" : "")}>
                                                    Start
                                                </button>
                                                <button onClick={() => { this.changeSelectedDeckTo(5) }} class={"col-4 " + (this.state.selectedDeckList == 5 ? "selected" : "")}>
                                                    Side deck
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                    <div class={"deckList" + (this.state.gridStyle == 1 || this.state.gridStyle == 3 ? " small-grid" : "") + (this.state.gridStyle == 2 || this.state.gridStyle == 3 ? " stacks" : "")}>
                                        <div class="decklist-top-row px-3">
                                            <div class="d-flex flex-row align-items-center">
                                                <div class="d-flex flex-row landscape-only align-items-center">
                                                <p>View</p>
                                                <Form.Select className="form-select mt-0 ms-2 me-4 w-auto card-type-select blurred-background" aria-label="Default select example"
                                                    onChange={(event) => {
                                                        this.setState({ gridStyle: event.target.value });
                                                        localStorage.setItem('preferedGridStyle', event.target.value)
                                                    }} value={this.state.gridStyle} >
                                                    <option value="0">Grid (big)</option>
                                                    <option value="1">Grid (small)</option>
                                                    <option value="2">Stacks (big)</option>
                                                    <option value="3">Stacks (small)</option>
                                                </Form.Select>
                                                </div>
                                                <p class="text-start">{this.state.deckSelectionInfo}</p>
                                            </div>
                                            {this.state.selectedDeckList == 1 ?
                                                (<p class="mb-0 text-end me-2">{(this.state.deckList_creatures.concat(this.state.deckList_artifactsAndEnchantments.concat(this.state.deckList_instantsAndSorceries.concat(this.state.deckList_tokens)))).reduce((total, card) => total + card.count, 0)} cards</p>)
                                                : (<div></div>)
                                            }
                                        </div>
                                        {(() => {
                                            if (this.state.selectedDeckList == 1) {
                                                return (
                                                    <div class="deckList_hordeDeck">
                                                        {
                                                            this.deckInfoCardCount("Creatures", this.state.deckList_creatures.reduce((total, card) => total + card.count, 0))
                                                        }
                                                        <div onMouseLeave={() => this.setZoomedCard(false)}>
                                                            {this.state.deckList_creatures.map(function (card, i) {
                                                                return (
                                                                    <Card_EditorDeckList card={card} cardClicked={this.cardOnDeckListClicked} key={this.deckListCardKey(card)} setZoomedCard={this.setZoomedCard} />
                                                                )
                                                            }, this)}
                                                        </div>
                                                        {
                                                            this.deckInfoCardCount("Artifacts and Enchantments", this.state.deckList_artifactsAndEnchantments.reduce((total, card) => total + card.count, 0))
                                                        }
                                                        <div onMouseLeave={() => this.setZoomedCard(false)}>
                                                            {this.state.deckList_artifactsAndEnchantments.map(function (card, i) {
                                                                return (
                                                                    <Card_EditorDeckList card={card} cardClicked={this.cardOnDeckListClicked} key={this.deckListCardKey(card)} setZoomedCard={this.setZoomedCard} />
                                                                )
                                                            }, this)}
                                                        </div>
                                                        {
                                                            this.deckInfoCardCount("Instants and Sorceries", this.state.deckList_instantsAndSorceries.reduce((total, card) => total + card.count, 0))
                                                        }
                                                        <div onMouseLeave={() => this.setZoomedCard(false)}>
                                                            {this.state.deckList_instantsAndSorceries.map(function (card, i) {
                                                                return (
                                                                    <Card_EditorDeckList card={card} cardClicked={this.cardOnDeckListClicked} key={this.deckListCardKey(card)} setZoomedCard={this.setZoomedCard} />
                                                                )
                                                            }, this)}
                                                        </div>
                                                        {
                                                            this.deckInfoCardCount("Tokens", this.state.deckList_tokens.reduce((total, card) => total + card.count, 0))
                                                        }
                                                        <div onMouseLeave={() => this.setZoomedCard(false)}>
                                                            {this.state.deckList_tokens.map(function (card, i) {
                                                                return (
                                                                    <Card_EditorDeckList card={card} cardClicked={this.cardOnDeckListClicked} key={this.deckListCardKey(card)} setZoomedCard={this.setZoomedCard} />
                                                                )
                                                            }, this)}
                                                        </div>
                                                    </div>
                                                )
                                            } else if (this.state.selectedDeckList == 2) {
                                                return (
                                                    <div class="deckList_hordeDeck">
                                                        {
                                                            this.deckInfoCardCount("Creatures", this.state.deckList_creatures.reduce((total, card) => total + card.count, 0))
                                                        }
                                                        <div onMouseLeave={() => this.setZoomedCard(false)}>
                                                            {this.state.deckList_creatures.map(function (card, i) {
                                                                return (
                                                                    <Card_EditorDeckList card={card} cardClicked={this.cardOnDeckListClicked} key={this.deckListCardKey(card)} unselected={this.indexOfCard(card, this.state.lategame) == -1} setZoomedCard={this.setZoomedCard} />
                                                                )
                                                            }, this)}
                                                        </div>
                                                        {
                                                            this.deckInfoCardCount("Artifacts and Enchantments", this.state.deckList_artifactsAndEnchantments.reduce((total, card) => total + card.count, 0))
                                                        }
                                                        <div onMouseLeave={() => this.setZoomedCard(false)}>
                                                            {this.state.deckList_artifactsAndEnchantments.map(function (card, i) {
                                                                return (
                                                                    <Card_EditorDeckList card={card} cardClicked={this.cardOnDeckListClicked} key={this.deckListCardKey(card)} unselected={this.indexOfCard(card, this.state.lategame) == -1} setZoomedCard={this.setZoomedCard} />
                                                                )
                                                            }, this)}
                                                        </div>
                                                        {
                                                            this.deckInfoCardCount("Instants and Sorceries", this.state.deckList_instantsAndSorceries.reduce((total, card) => total + card.count, 0))
                                                        }
                                                        <div onMouseLeave={() => this.setZoomedCard(false)}>
                                                            {this.state.deckList_instantsAndSorceries.map(function (card, i) {
                                                                return (
                                                                    <Card_EditorDeckList card={card} cardClicked={this.cardOnDeckListClicked} key={this.deckListCardKey(card)} unselected={this.indexOfCard(card, this.state.lategame) == -1} setZoomedCard={this.setZoomedCard} />
                                                                )
                                                            }, this)}
                                                        </div>
                                                        {
                                                            this.deckInfoCardCount("Tokens", this.state.deckList_tokens.reduce((total, card) => total + card.count, 0))
                                                        }
                                                        <div onMouseLeave={() => this.setZoomedCard(false)}>
                                                            {this.state.deckList_tokens.map(function (card, i) {
                                                                return (
                                                                    <Card_EditorDeckList card={card} cardClicked={this.cardOnDeckListClicked} key={this.deckListCardKey(card)} unselected={this.indexOfCard(card, this.state.lategame) == -1} setZoomedCard={this.setZoomedCard} />
                                                                )
                                                            }, this)}
                                                        </div>
                                                    </div>
                                                )
                                            } else if (this.state.selectedDeckList == 3) {
                                                return (
                                                    <div class="deckList_hordeDeck">
                                                        <div onMouseLeave={() => this.setZoomedCard(false)}>
                                                            {this.state.deckList_tokens.map(function (card, i) {
                                                                return (
                                                                    <Card_EditorDeckList card={card} cardClicked={this.cardOnDeckListClicked} key={this.deckListCardKey(card)} hideCount={true} setZoomedCard={this.setZoomedCard} />
                                                                )
                                                            }, this)}
                                                            {this.state.tokens.map(function (card, i) {
                                                                return (
                                                                    <Card_EditorDeckList card={card} cardClicked={this.cardOnDeckListClicked} key={this.deckListCardKey(card)} hideCount={true} setZoomedCard={this.setZoomedCard} />
                                                                )
                                                            }, this)}
                                                        </div>
                                                    </div>
                                                )
                                            } else if (this.state.selectedDeckList == 4) {
                                                return (
                                                    <div class="deckList_hordeDeck">
                                                        <div onMouseLeave={() => this.setZoomedCard(false)}>
                                                            {this.state.weak.map(function (card, i) {
                                                                return (
                                                                    <Card_EditorDeckList card={card} cardClicked={this.cardOnDeckListClicked} key={this.deckListCardKey(card)} setZoomedCard={this.setZoomedCard} />
                                                                )
                                                            }, this)}
                                                        </div>
                                                    </div>
                                                )
                                            } else if (this.state.selectedDeckList == 5) {
                                                return (
                                                    <div class="deckList_hordeDeck">
                                                        <div onMouseLeave={() => this.setZoomedCard(false)}>
                                                            {this.state.powerful.map(function (card, i) {
                                                                return (
                                                                    <Card_EditorDeckList card={card} cardClicked={this.cardOnDeckListClicked} key={this.deckListCardKey(card)} setZoomedCard={this.setZoomedCard} />
                                                                )
                                                            }, this)}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                            <ReprintPicker showPicker={this.state.showReprintPicker} reprints={this.state.reprints} reprintPicked={this.pickNewReprint} />
                            <SavePanel return={() => this.setState({ showSavePanel: false })} showSavePanel={this.state.showSavePanel} deckListText={this.state.TMP_Decklist} deckListText_Classic={this.state.TMP_Decklist_Classic} />
                            <ArtPicker showPicker={this.state.showArtPicker}
                                closePanel={() => { this.setState({ showArtPicker: false }) }}
                                cardList={this.state.deckList_creatures.concat(this.state.deckList_artifactsAndEnchantments.concat(this.state.deckList_instantsAndSorceries.concat(this.state.deckList_tokens)))}
                                artPicked={(artId) => {
                                    const deckInfo = this.state.deckInfo
                                    deckInfo.artId = artId
                                    this.setState({ deckInfo: deckInfo })
                                    this.setState({ showArtPicker: false })
                                }} />
                            <AddCustomCard shouldShowPanel={this.state.showCustomCardPanel} changeShoulShowPanel={value => { this.setState({ showCustomCardPanel: value }) }} changeCardToShow={(card) => { this.changeCardToShow(card); this.addCardToSelectedDeck(card) }} />
                        </div >
                    } />
                </div>
            )
        }

    }
}

function DeckIntro(props) {
    const deckInfo = props.deckInfo
    const isAuthorOfTheDeck = props.isAuthorOfTheDeck
    const deckNameChanged = props.deckNameChanged
    const [addDeckInteractable, setAddDeckInteractable] = useState(1)
    const [likeDeckInteractable, setLikeDeckInteractable] = useState(1)
    const saveDeckText = props.saveDeckText

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            const userData = JSON.parse(sessionStorage.getItem("userData"))

            if (user) {
                if (userData == undefined || userData.userCollection == undefined) {
                    // load collection
                    await getDoc(doc(db, "usersCollection", user.uid))
                        .then(async (userDeckIdsSnapshot) => {
                            console.log("CALL - iniUserDecks - getCollection")
                            const decksCollection = userDeckIdsSnapshot.data().collection
                            const newUserData = JSON.parse(sessionStorage.getItem("userData"))

                            sessionStorage.setItem("userData", JSON.stringify({
                                uid: user.uid,
                                decks: newUserData.decks,
                                shouldUpdateDecks: true,
                                userCollection: decksCollection
                            }))

                            decksCollection?.forEach(deckId => {
                                if (deckId == props.deckId) {
                                    setAddDeckInteractable(-1)
                                }
                            })
                        })
                } else {
                    userData.userCollection?.forEach(deckId => {
                        if (deckId == props.deckId) {
                            setAddDeckInteractable(-1)
                        }
                    })
                }

                if (deckInfo.authorUuid == false) {
                    setAddDeckInteractable(-1)
                    setLikeDeckInteractable(-1)
                } else if (user.uid == deckInfo.authorUuid) {
                    setAddDeckInteractable(-1)
                    setLikeDeckInteractable(-1)
                } else {
                    // Check if allowed to like the deck
                    if (deckInfo.likes != undefined && deckInfo.likes.includes(user.uid)) {
                        setLikeDeckInteractable(-1)
                    }
                }
            } else {
                setAddDeckInteractable(-1)
                setLikeDeckInteractable(-1)
            }
        })
    }, [])

    return (
        <div class="d-flex flex-column">
            <div class="deck-intro d-flex align-items-middle text-white">
                <DeckArt artId={deckInfo.artId} classes={"background"} />
                <div class="d-flex flex-row deck-info">
                    <div class={(isAuthorOfTheDeck ? "editable" : "") + " art-section"} onClick={props.isAuthorOfTheDeck ? props.showArtPicker : () => { console.log("Only author can change the image") }} >
                        <DeckArt artId={deckInfo.artId} classes={"deck-art shadowed landscape-only"} />
                        {
                            isAuthorOfTheDeck ? (<img class="deck-art-edit landscape-only" src={require("../../assets/Icons/edit.png")} />) : (<div></div>)
                        }
                    </div>

                    <div class="d-flex flex-column w-100 justify-content-between deck-title-section">
                        <div class="d-flex flex-column">

                            {
                                isAuthorOfTheDeck ?
                                    (<input type="text" class="" id="DeckName" maxLength="25" defaultValue={deckInfo.name} onChange={(event) => {
                                        const deckInfoTmp = deckInfo
                                        deckInfoTmp.name = event.target.value
                                        deckNameChanged(deckInfoTmp)
                                    }} />) :
                                    (<h1 id="DeckName">{deckInfo.name}</h1>)
                            }
                            <div class="deck-author-name mt-2">
                                <UserName uid={deckInfo.authorUuid} displayName={deckInfo.author} />
                            </div>
                        </div>
                        <div>
                            <div class="d-flex flex-row align-items-center justify-items-start like-row">
                                {
                                    deckInfo.likesCount != undefined && deckInfo.likesCount > 0 ? (<h2 class="m-0">{deckInfo.likesCount} <img src={require('./../../assets/Icons/coeur.png')} width={35} height={35} /></h2>) : (<h2></h2>)
                                }
                                {
                                    likeDeckInteractable == -1 ? (<div></div>) :
                                        (<Button text="Like" classes=" primary" clicked={() => { props.likeDeck(); setLikeDeckInteractable(-1); deckInfo.likesCount == undefined ? deckInfo.likesCount = 1 : deckInfo.likesCount += 1 }} />)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="button-row d-flex flex-row text-white justify-content-between px-3 py-2">
                <div class="d-flex flex-column w-100">
                    <div class="d-flex flex-row">
                        <div class="d-flex flex-row align-items-center ">
                            {
                                addDeckInteractable == -1 ? (<div></div>) :
                                    (<Button text={addDeckInteractable == 1 ? "Add to your decks" : "Adding deck ..."} clicked={async () => {
                                        if (addDeckInteractable != 1) {
                                            return
                                        }
                                        setAddDeckInteractable(0)
                                        await (props.addDeck(false))
                                            .then(() => {
                                                setAddDeckInteractable(-1)
                                            })
                                    }} />)
                            }
                            <Button text="Deck list" clicked={props.getDeckList} />
                            <Button text="Play" clicked={props.play} classes=" purpleOutline" />
                        </div>
                        <div class="d-flex flex-row align-items-center landscape-only">
                            {props.isAuthorOfTheDeck ? (
                                <label class="isDeckPublic px-2">
                                    <input type="checkbox" id="IsPublic" defaultChecked={deckInfo.public} onChange={props.onChangeIsPublic} />
                                    Public
                                </label>) : (<label class="isDeckPublic"></label>)

                            }
                            {
                                auth.currentUser != null && (isAuthorOfTheDeck || deckInfo.authorUuid == "") ?
                                    (<Button text={saveDeckText} clicked={props.saveDeck} id="Save-button" classes=" purpleOutline" />) : (<div></div>)
                            }
                        </div>
                    </div>
                    {
                        props.isAuthorOfTheDeck || deckInfo.intro != "" || deckInfo.specialRules != "" ? (
                            <div class="d-flex flex-row intro-and-rules row">
                                <Form className='d-flex flex-column col-md-6'>
                                    <Form.Group className="w-100" controlId="exampleForm.ControlTextarea1">
                                        <h2>Introduction</h2>
                                        {
                                            props.isAuthorOfTheDeck ?
                                                (<Form.Control as="textarea" rows={8} className='blurred-background w-100' defaultValue={deckInfo.intro} onChange={props.onChangeIntro} />) :
                                                (<p>{deckInfo.intro}</p>)
                                        }

                                    </Form.Group>
                                </Form>
                                <Form className='d-flex flex-column col-md-6'>
                                    <Form.Group className="w-100" controlId="exampleForm.ControlTextarea1">
                                        <h2>Special rules</h2>
                                        {
                                            props.isAuthorOfTheDeck ?
                                                (<Form.Control as="textarea" rows={8} className='blurred-background w-100' defaultValue={deckInfo.specialRules} onChange={props.onChangeSpecialRules} />) :
                                                (<p>{deckInfo.specialRules}</p>)
                                        }
                                    </Form.Group>
                                </Form>
                            </div>
                        ) : (<div></div>)
                    }

                </div>







            </div>
        </div>
    )
}

function Card_EditorPanel(props) {
    const card = props.card

    return (
        <button class="card-editor-panel">
            <CardIMG card={card} onClick={props.cardClicked} />
        </button>
    )
}

function Card_EditorDeckList(props) {
    const [card, setCard] = useState(props.card)
    const index = props.index

    function cardClicked() {
        console.log("Clicking on " + card.id)
        props.cardClicked(card)
    }

    return (
        <button class={"card fadeIn" + (props.unselected ? " not-lategame" : "")} onMouseEnter={() => props.setZoomedCard(props.card)} >
            <CardIMG card={card} onClick={cardClicked} flipAll={() => { card.showFront = !card.showFront; props.setZoomedCard(card) }} />
            {card.count > 1 && (<p class="cardCount">{props.hideCount ? "" : card.count}</p>)}
            {card.counters > 0 ? (<h1 class="blurred-background shadowed fadeIn">{card.counters}</h1>) : (<h1 />)}
            {card.capacities.castFromGraveyard ? (<img src={card.capacities.castFromGraveyardThenExile ? FlashBackIconThenExile : FlashBackIcon} class="blurred-background shadowed flashback-icon" />) : (<div></div>)}
            {card.capacities.cantAttackOrBlock ? (<p class="cantAttackOrBlock shadowed">Can't attack</p>) : (<div></div>)}
        </button>
    )
}

function SearchResult(props) {
    const result = props.result

    function resultClicked() {
        props.resultClicked(result)
    }

    return (
        <button class="search-result" onClick={resultClicked} >
            <DeckArt artId={result.id} classes={"result-image"} />
            <div class="d-flex flex-row justify-content-between w-100 align-items-center">
                <p class="px-2">{result.cardName}</p>
                {result.cost != undefined && result.cost != "" ?
                    (
                        <div class="mana-cost">
                            {result.cost.map(function (cost, i) {
                                return (
                                    <img src={require('./../../assets/ManaCosts/' + cost + '.svg')} key={i} />
                                )
                            })}
                        </div>
                    ) : (<div class="mana-cost"></div>)
                }
            </div>


        </button>
    )
}

function ReprintPicker(props) {
    return (
        <div>
            {props.showPicker ?
                (
                    <div class="fullscreen-blurred-background fadeIn">
                        <div class="reprint-picker">
                            <h2>Choose a print</h2>
                            <div>
                                {props.reprints.map(function (card, i) {
                                    return (
                                        <Card_Reprint card={card} cardClicked={props.reprintPicked} key={i} />
                                    )
                                }, this)}
                            </div>
                        </div>
                    </div>
                ) : (<div></div>)
            }
        </div>
    )

    function Card_Reprint(props) {
        const card = props.card

        function cardClicked() {
            props.cardClicked(card)
        }

        return (
            <button class="card-reprint">
                <CardIMG card={card} onClick={cardClicked} />
            </button>
        )
    }
}

function SavePanel(props) {
    const [showClassic, setShowClassic] = useState(true)
    return (
        <div>
            {props.showSavePanel ?
                (
                    <HalfScreenPanel
                        component={
                            <div class="deck-list-panel d-flex flex-column">
                                <h1>Deck list</h1>
                                <div class="d-flex flex-row">
                                    <button class="default-button ms-0" onClick={() => { setShowClassic(!showClassic) }}>{showClassic ? "Switch to horde format" : "Switch to classic format"}</button>
                                </div>
                                {
                                    showClassic ?
                                        (<textarea class="deckList-text blurred-background" name="story" value={props.deckListText_Classic} readOnly></textarea>
                                        )
                                        :
                                        (<textarea class="deckList-text blurred-background" name="story" value={props.deckListText} readOnly></textarea>
                                        )
                                }

                                {/*
                                <div>
                                    <Button text="Return" clicked={props.return} />
                                    <Button text="Download as .txt" clicked={props.saveTxt} />
                        </div>*/}
                            </div>
                        }
                        imageName={"img_import.jpg"}
                        copyright="Cristi Balanescu"
                        hidePanel={props.return}
                    />

                ) : (<div></div>)
            }
        </div>
    )
}

function SocialPanel(props) {
    const deckInfo = props.deckInfo
    const [addDeckInteractable, setAddDeckInteractable] = useState(1)
    const [likeDeckInteractable, setLikeDeckInteractable] = useState(1)

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            const userData = JSON.parse(sessionStorage.getItem("userData"))

            if (user) {
                if (userData == undefined || userData.userCollection == undefined) {
                    // load collection
                    await getDoc(doc(db, "usersCollection", user.uid))
                        .then(async (userDeckIdsSnapshot) => {
                            console.log("CALL - iniUserDecks - getCollection")
                            const decksCollection = userDeckIdsSnapshot.data().collection
                            const newUserData = JSON.parse(sessionStorage.getItem("userData"))

                            sessionStorage.setItem("userData", JSON.stringify({
                                uid: user.uid,
                                decks: newUserData.decks,
                                shouldUpdateDecks: true,
                                userCollection: decksCollection
                            }))

                            decksCollection?.forEach(deckId => {
                                if (deckId == props.deckId) {
                                    setAddDeckInteractable(-1)
                                }
                            })
                        })
                } else {
                    userData.userCollection?.forEach(deckId => {
                        if (deckId == props.deckId) {
                            setAddDeckInteractable(-1)
                        }
                    })
                }

                if (deckInfo.authorUuid == false) {
                    setAddDeckInteractable(-1)
                    setLikeDeckInteractable(-1)
                } else if (user.uid == deckInfo.authorUuid) {
                    setAddDeckInteractable(-1)
                    setLikeDeckInteractable(-1)
                } else {
                    // Check if allowed to like the deck
                    if (deckInfo.likes != undefined && deckInfo.likes.includes(user.uid)) {
                        setLikeDeckInteractable(-1)
                    }
                }
            } else {
                setAddDeckInteractable(-1)
                setLikeDeckInteractable(-1)
            }
        })
    }, [])

    return (
        <div class="deck-info-panel">
            <div class="image-picker-box">
                <div onClick={props.isAuthorOfTheDeck ? props.showArtPicker : () => { console.log("Only author can change the image") }} >
                    <DeckArt artId={deckInfo.artId} classes={"deck-image"} />
                </div>
                <p>{props.isAuthorOfTheDeck ? "Click the image to pick another art" : ""}</p>
                <div class="deck-author-name">
                    <UserName uid={deckInfo.authorUuid} displayName={deckInfo.author} />
                </div>
                {
                    deckInfo.likesCount != undefined && deckInfo.likesCount > 0 ? (<h2>{deckInfo.likesCount} <img src={require('./../../assets/Icons/coeur.png')} /></h2>) : (<h2></h2>)
                }

            </div>

            <div class="deck-info-interaction">
                <span>
                    {props.isAuthorOfTheDeck ? (
                        <label class="isDeckPublic">
                            <input type="checkbox" id="IsPublic" defaultChecked={deckInfo.public} onChange={props.onChangeIsPublic} />
                            Public
                        </label>) : (<label class="isDeckPublic"></label>)
                    }

                </span>

                <div class="d-inline">
                    {
                        addDeckInteractable == -1 ? (<div></div>) :
                            (<Button text={addDeckInteractable == 1 ? "Add to your decks" : "Adding deck ..."} clicked={async () => {
                                if (addDeckInteractable != 1) {
                                    return
                                }
                                setAddDeckInteractable(0)
                                await (props.addDeck(false))
                                    .then(() => {
                                        setAddDeckInteractable(-1)
                                    })
                            }} />)
                    }
                    {
                        likeDeckInteractable == -1 ? (<div></div>) :
                            (<Button text="Like" clicked={() => { props.likeDeck(); setLikeDeckInteractable(-1); deckInfo.likesCount == undefined ? deckInfo.likesCount = 1 : deckInfo.likesCount += 1 }} />)
                    }

                    <Button text="Deck list" clicked={props.getDeckList} />
                </div>


                <Form className='d-flex flex-column intro-and-rules'>
                    <Form.Group className="w-100" controlId="exampleForm.ControlTextarea1">
                        <h2>Intro</h2>
                        {
                            props.isAuthorOfTheDeck ?
                                (<Form.Control as="textarea" rows={5} className='blurred-background w-100' defaultValue={deckInfo.intro} onChange={props.onChangeIntro} />) :
                                (<p>{deckInfo.intro}</p>)
                        }

                    </Form.Group>

                    <Form.Group className="w-100" controlId="exampleForm.ControlTextarea1">
                        <h2>Special rules</h2>
                        {
                            props.isAuthorOfTheDeck ?
                                (<Form.Control as="textarea" rows={8} className='blurred-background w-100' defaultValue={deckInfo.specialRules} onChange={props.onChangeSpecialRules} />) :
                                (<p>{deckInfo.specialRules}</p>)
                        }
                    </Form.Group>
                </Form>
            </div>
        </div >
    )

    function Comment(props) {
        return (
            <div class="comment blurred-background">
                <h1>{props.author}</h1>
                <h2>{props.date}</h2>
                <p>{props.comment}</p>
            </div>
        )
    }
}

function ArtPicker(props) {
    return (
        <div>
            {props.showPicker ?
                (
                    <FullScreenPanel
                        title="Choose an art for your deck"
                        component={
                            <div class="image-picker">
                                {props.cardList.map(function (card, i) {
                                    return (
                                        <Card_Art card={card} cardClicked={props.artPicked} key={i} />
                                    )
                                }, this)}
                            </div>
                        }
                        hidePanel={props.closePanel} />
                ) : (<div></div>)
            }
        </div>
    )

    function Card_Art(props) {
        const card = props.card

        function cardClicked() {
            props.cardClicked(card.id)
        }

        if (card.id.includes("https://i.imgur.com") || card.id.includes("D::")) {
            return
        }

        return (
            <button class="shadowed" onClick={cardClicked}>
                <img src={"https://api.scryfall.com/cards/" + card.id + "?format=img&version=art_crop"} class="deck-image" />
            </button>
        )
    }
}

function AddCustomCard(props) {
    const [cardName, setCardName] = useState("")
    const [cardType, setCardType] = useState("creature")
    const [cardUrl, setCardUrl] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [isDFC, setIsDFC] = useState(false)
    const [cardName_Back, setCardName_Back] = useState("")
    const [cardUrl_Back, setCardUrl_Back] = useState("")

    let cardTmp = {
        count: 1,
        id: isDFC ? (cardUrl + "://:" + cardUrl_Back) : cardUrl,
        type: cardType,
        capacities: {
            castFromGraveyard: false,
            castFromGraveyardThenExile: false,
            cantAttackOrBlock: false
        },
        oracleId: "NONE",
        set: "X",
        cardName: isDFC ? "Custom Card // Custom Card" : "Custom Card",
        showFront: true
    }

    if (props.shouldShowPanel == false) {
        return
    }

    function closePanel() {
        setCardType("creature")
        setCardName("")
        setCardUrl("")
        setErrorMessage("")
        props.changeShoulShowPanel(false)
    }

    function addCard(event) {
        console.log(cardUrl)
        event.preventDefault();

        if (cardUrl.length > 80 || !(cardUrl.includes("https://i.imgur.com/") || cardUrl.includes("D::"))) {
            setErrorMessage("Incorrect card URL")
            return
        }

        if (cardName.length > 20) {
            setErrorMessage("Incorrect card Name")
            return
        }

        if (isDFC) {
            if (cardUrl_Back.length > 80 || !(cardUrl_Back.includes("https://i.imgur.com/") || cardUrl_Back.includes("D::"))) {
                setErrorMessage("Incorrect card URL")
                return
            }

            if (cardName_Back.length > 20) {
                setErrorMessage("Incorrect card Name")
                return
            }
        }

        let cardNameFront = cardName.length == 0 ? "Custom Card" : cardName
        let cardNameBack = cardName_Back.length == 0 ? "Custom Card" : cardName_Back

        let card = {
            count: 1,
            id: isDFC ? (cardUrl + "://:" + cardUrl_Back) : cardUrl,
            type: cardType,
            capacities: {
                castFromGraveyard: false,
                castFromGraveyardThenExile: false,
                cantAttackOrBlock: false
            },
            oracleId: "NONE",
            set: "X",
            cardName: isDFC ? cardNameFront + " // " + cardNameBack : cardNameFront
        }
        props.changeCardToShow(card)

        setCardName("")
        setCardType("creature")
        setCardUrl("")
        setIsDFC(false)
        setCardName_Back("")
        setCardUrl_Back("")
        closePanel()
    }

    function onChangeCardUrl(event) {
        let cardUrlTmp = event.target.value
        if (cardUrlTmp.includes("/attachments/1127961672225673256/")) {
            cardUrlTmp = cardUrlTmp.split("1127961672225673256/").pop()
            cardUrlTmp = cardUrlTmp.split('?')[0]
            cardUrlTmp = "D::" + cardUrlTmp
        }
        if (cardUrlTmp.length > 80 || !(cardUrlTmp.includes("https://i.imgur.com/") || cardUrlTmp.includes("D::"))) {
            setErrorMessage("Incorrect card URL, only Imgur")
            return
        }
        setCardUrl(cardUrlTmp)
        cardTmp.id = isDFC ? (cardUrlTmp + "://:" + cardUrl_Back) : cardUrlTmp
        setErrorMessage("")
    }

    function onChangeCardUrl_Back(event) {
        let cardUrlTmp = event.target.value
        if (cardUrlTmp.includes("/attachments/1127961672225673256/")) {
            cardUrlTmp = cardUrlTmp.split("1127961672225673256/").pop()
            cardUrlTmp = cardUrlTmp.split('?')[0]
            cardUrlTmp = "D::" + cardUrlTmp
        }
        if (cardUrlTmp.length > 80 || !(cardUrlTmp.includes("https://i.imgur.com/") || cardUrlTmp.includes("D::"))) {
            setErrorMessage("Incorrect card URL, only Imgur")
            return
        }

        setCardUrl_Back(cardUrlTmp)
        cardTmp.id = isDFC ? (cardUrl + "://:" + cardUrlTmp) : cardUrl
        setErrorMessage("")
    }

    function onChangeCardName(event) {
        let cardNameTmp = event.target.value
        if (cardNameTmp.length > 20) {
            setErrorMessage("Name is too long")
            return
        }
        setCardName(cardNameTmp)
        setErrorMessage("")
    }

    function onChangeCardName_Back(event) {
        let cardNameTmp = event.target.value
        if (cardNameTmp.length > 20) {
            setErrorMessage("Back face name is too long")
            return
        }
        setCardName_Back(cardNameTmp)
        setErrorMessage("")
    }

    function onChangeCardType(event) {
        setCardType(event.target.value)
        console.log("Change to " + cardType)
    }

    return (
        <FullScreenPanel
            title=""
            component={
                <div class="custom-image-panel row container mx-auto align-middle">
                    <div class="col-4 ms-auto card-info classic-pannel">
                        <h2>Add custom card</h2>
                        <Form onSubmit={addCard}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Card name (optional)</Form.Label>
                                <Form.Control type="text" onChange={onChangeCardName.bind(this)} placeholder="My card" className="blurred-background" />
                            </Form.Group>
                            {isDFC ?
                                (<Form.Group className="mb-3 ms-1 ps-3" controlId="exampleForm.ControlInput2">
                                    <Form.Label>Card back name (optional)</Form.Label>
                                    <Form.Control type="text" onChange={onChangeCardName_Back.bind(this)} placeholder="Back face of my card" className="blurred-background" />
                                </Form.Group>) : (<div></div>)
                            }

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Card type</Form.Label>
                                <Form.Select className="form-select mt-0 card-type-select blurred-background" aria-label="Default select example"
                                    onChange={onChangeCardType.bind(this)}
                                    value={cardType}>
                                    <option value="creature">Creature</option>
                                    <option value="token">Token</option>
                                    <option value="enchantment">Enchantment</option>
                                    <option value="artifact">Artifact</option>
                                    <option value="sorcery">Sorcery</option>
                                    <option value="instant">Insant</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Card image URL from Imgur</Form.Label>
                                <Form.Control type="text" onChange={onChangeCardUrl.bind(this)} placeholder="https://i.imgur.com/MY-CARD" className="blurred-background" />
                            </Form.Group>
                            {isDFC ?
                                (<Form.Group className="mb-3 ms-1 ps-3" controlId="exampleForm.ControlTextarea2">
                                    <Form.Label>Back face image URL</Form.Label>
                                    <Form.Control type="text" onChange={onChangeCardUrl_Back.bind(this)} placeholder="https://i.imgur.com/MY-CARD-BACK" className="blurred-background" />
                                </Form.Group>) : (<div></div>)
                            }

                            <label><input type="checkbox" checked={isDFC} onChange={() => setIsDFC(!isDFC)} />Is double faced card</label>
                            <p>{errorMessage}</p>
                            <Button type="submit" className="default-button" text={"Add card"} />
                        </Form>

                    </div>
                    <div class="col-4 me-auto">
                        <h2>Preview</h2>
                        {
                            cardUrl.length > 20 ? (
                                <div class="card mx-auto">
                                    <CardIMG card={cardTmp} />
                                </div>
                            ) : (<div></div>)
                        }
                    </div>
                </div>
            }
            hidePanel={closePanel} />
    )
}
/*
export function ZoomedCard(props) {
    const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 })
    const cardSize = useRef({
        width: ((window.innerHeight / 100) * props.widthVh),
        height: ((window.innerHeight / 100) * props.widthVh * 1.4)
    })
    const limits = useRef({
        minT: cardSize.current.height / 2,
        maxT: window.innerHeight - cardSize.current.height / 2,
        minL: cardSize.current.width / 2,
        maxL: window.screen.width - cardSize.current.width / 2,
    })

    useEffect(() => {
        const handleWindowMouseMove = e => {
            let t = e.screenY
            let l = e.pageX
            if (t < limits.current.minT) {
                t = limits.current.minT
            }
            if (t > limits.current.maxT) {
                t = limits.current.maxT
            }
            if (l < limits.current.minL) {
                l = limits.current.minL
            }
            if (l > limits.current.maxL) {
                l = limits.current.maxL
            }
            setCursorPosition({ top: t, left: l });
        };
        window.addEventListener('pointermove', handleWindowMouseMove);

        return () => {
            window.removeEventListener(
                'pointermove',
                handleWindowMouseMove,
            );
        };
    }, []);

    let card = props.zoomedCard
    if (card) {
        return (<div style={{ position: 'fixed', ...cursorPosition }} key={card.cardId} class={"shadowed zoomed-card " + (cursorPosition.left + cardSize.current.width < limits.current.maxL ? "" : " other-side")}>
            <CardIMG card={card} />
        </div>)
    }
}*/


export function ZoomedCard(props) {
    let card = props.zoomedCard
    if (card) {
        return (<div key={card.cardId} class={"focused-card "}>
            <CardIMG card={card} forcedShowBack={card.showFront} />
        </div>)
    }
}



export default DeckEditor