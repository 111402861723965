import ScrollContainer from 'react-indiana-drag-scroll'
import React, { useEffect, useState } from "react";
import '../DeckPicker/DeckPicker.css'
import './DeckBrowser.css'
import '../Commons/Commons.css'
import { Button, BigButton, SelectableElement, UserName, DeckArt, Footer } from '../Commons/Commons.jsx'
import { Container, InputGroup, Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import { collection, getDocs, query, where, orderBy, limit, startAfter } from "firebase/firestore";
import { db } from '../../firebase';
import NewNavBar from '../HomePage/NewNavBar.jsx';

const { DECKS } = require("../../utils/DB");



class DeckBrowser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            categories: [
                {
                    "title": "",
                    "decks": []
                }
            ],
            query: false,
            lastPaginationElement: false
        };

        if (props.searchQuery && props.searchQuery == "PUBLIC_ORDERBY_recent") {
            this.searchAllRecentDeck()
        } else if (props.searchQuery && props.searchQuery == "PUBLIC_ORDERBY_top") {
            this.searchAllTopDeck()
        } else if (props.searchQuery && !props.searchQuery.includes("PUBLIC_ORDERBY")) {
            this.querySearch(props.searchQuery)
        } else {
            this.iniDefault()
        }

        //this.iniCategories()
    }

    iniDefault = () => {
        var data = require('./test.json')
        this.state.categories = data.lists

        // Add most recent row
        this.iniMostRecentDecks()
        this.iniTopDecks()
    }

    iniMostRecentDecks = async () => {
        await getDocs(query(collection(db, "decks"), where("public", "==", true), orderBy("lastModified", "desc"), limit(8)))
            .then((querySnapshot) => {
                console.log(querySnapshot)
                const decksData = querySnapshot.docs
                    .map((doc) => ({ ...doc.data(), deckId: doc.id }))

                const categories = this.state.categories
                categories[0].decks = decksData
                this.setState({ categories: categories })
                console.log("CALL - Recent decks")
            })
    }

    iniTopDecks = async () => {
        await getDocs(query(collection(db, "decks"), where("public", "==", true), orderBy("likesCount", "desc"), limit(8)))
            .then((querySnapshot) => {
                console.log(querySnapshot)
                const decksData = querySnapshot.docs
                    .map((doc) => ({ ...doc.data(), deckId: doc.id }))

                const categories = this.state.categories
                categories[1].decks = decksData
                this.setState({ categories: categories })
                console.log("CALL - Top decks")
            })
    }

    iniCategories = async () => {
        await getDocs(collection(db, "decks"))
            .then((querySnapshot) => {
                const decksData = querySnapshot.docs
                    .map((doc) => ({ ...doc.data(), deckId: doc.id }))
                this.setState({
                    categories: [
                        {
                            "title": "Search results for ALL",
                            "decks": decksData
                        }
                    ]
                })
            })
    }

    /*--------------------- RECENT ---------------------*/

    searchAllRecentDeck = async () => {
        await getDocs(query(collection(db, "decks"), where("public", "==", true), orderBy("lastModified", "desc"), limit(24)))
            .then((querySnapshot) => {
                this.appendRecentDeckData(querySnapshot)
            })
    }

    loadMoreRecentDeck = async () => {
        const lastElement = this.state.lastPaginationElement
        if (lastElement == false) {
            return
        }
        await getDocs(query(collection(db, "decks"), where("public", "==", true), orderBy("lastModified", "desc"), startAfter(lastElement), limit(24)))
            .then((querySnapshot) => {
                this.appendRecentDeckData(querySnapshot)
            })
    }

    appendRecentDeckData = (querySnapshot) => {
        const decksData = querySnapshot.docs
            .map((doc) => ({ ...doc.data(), deckId: doc.id }))

        this.setState({
            categories: [
                {
                    "title": "All public decks ordered by recently updated",
                    "decks": this.state.categories[0].decks.concat(decksData)
                }
            ]
        })
        this.setState({ query: "recently updated" })
        if (querySnapshot.docs.length < 24) {
            this.setState({ lastPaginationElement: false })
        } else {
            this.setState({ lastPaginationElement: querySnapshot.docs[querySnapshot.docs.length - 1] })
        }
    }

    /*--------------------- TOP ---------------------*/

    searchAllTopDeck = async () => {
        await getDocs(query(collection(db, "decks"), where("public", "==", true), orderBy("likesCount", "desc"), limit(24)))
            .then((querySnapshot) => {
                this.appendTopDeckData(querySnapshot)
            })
    }

    loadMoreTopDeck = async () => {
        const lastElement = this.state.lastPaginationElement
        if (lastElement == false) {
            return
        }
        await getDocs(query(collection(db, "decks"), where("public", "==", true), orderBy("likesCount", "desc"), startAfter(lastElement), limit(24)))
            .then((querySnapshot) => {
                this.appendTopDeckData(querySnapshot)
            })
    }

    appendTopDeckData = (querySnapshot) => {
        const decksData = querySnapshot.docs
            .map((doc) => ({ ...doc.data(), deckId: doc.id }))

        this.setState({
            categories: [
                {
                    "title": "All public decks ordered by likes count",
                    "decks": this.state.categories[0].decks.concat(decksData)
                }
            ]
        })
        this.setState({ query: "top decks" })
        if (querySnapshot.docs.length < 24) {
            this.setState({ lastPaginationElement: false })
        } else {
            this.setState({ lastPaginationElement: querySnapshot.docs[querySnapshot.docs.length - 1] })
        }
    }

    /*--------------------- SEARCH ---------------------*/

    searchForDecks = (event) => {
        event.preventDefault();
        let query = document.getElementById("DeckSearchField").value

        if (query != undefined && query != "") {
            this.querySearch(query)
            window.history.pushState({}, null, '/DeckBrowser/' + query)
        } else if (query != undefined) {
            this.iniDefault()
            this.setState({ query: false })
            window.history.pushState({}, null, '/decks')
        }
    }

    querySearch = async (myQuery) => {
        let splited = myQuery.toLowerCase().split(' ').filter(function (e) { return e !== '' && e.length > 1 })
        const decksRef = collection(db, "decks")
        const q = query(decksRef, where("nameKeywords", "array-contains-any", splited), where("public", "==", true), orderBy("lastModified", "desc"), limit(24))
        this.setState({
            categories: [
                {
                    "title": "Searching...",
                    "decks": []
                }
            ]
        })
        this.setState({ query: myQuery })

        await getDocs(q)
            .then((querySnapshot) => {

                let categories = [
                    {
                        "title": "Search results for " + myQuery,
                        "decks": []
                    }
                ]

                querySnapshot.forEach((deck) => {
                    categories[0].decks.push({ ...deck.data(), deckId: deck.id })
                })

                if (categories[0].decks.length == 0) {
                    categories = [
                        {
                            "title": "No decks found",
                            "decks": []
                        }
                    ]
                }

                this.setState({ categories: categories })
                if (querySnapshot.docs.length < 24) {
                    this.setState({ lastPaginationElement: false })
                } else {
                    this.setState({ lastPaginationElement: querySnapshot.docs[querySnapshot.docs.length - 1] })
                }
            })
    }

    loadMoreSearchDeck = async () => {
        const lastElement = this.state.lastPaginationElement
        if (lastElement == false) {
            return
        }
        let myQuery = this.state.query
        if (myQuery == false) {
            return
        }

        let splited = myQuery.toLowerCase().split(' ').filter(function (e) { return e !== '' && e.length > 1 })
        const decksRef = collection(db, "decks")
        const q = query(decksRef, where("nameKeywords", "array-contains-any", splited), where("public", "==", true), orderBy("likesCount", "desc"), startAfter(lastElement), limit(24))

        await getDocs(q)
            .then((querySnapshot) => {
                let categories = this.state.categories
                querySnapshot.forEach((deck) => {
                    categories[0].decks.push({ ...deck.data(), deckId: deck.id })
                })

                this.setState({ categories: categories })
                if (querySnapshot.docs.length < 24) {
                    this.setState({ lastPaginationElement: false })
                } else {
                    this.setState({ lastPaginationElement: querySnapshot.docs[querySnapshot.docs.length - 1] })
                }
            })
    }

    render() {
        return (
            <div id="DeckBrowser" class="container-fluid p-0">
                <NewNavBar selected="Browser" />
                <div class="main d-flex flex-column container-fluid">
                    <Container className='mt-4'>
                        <Row>
                            <Col md={6} className='home-title'>
                                <h1 class="text-white">Explore</h1>
                            </Col>
                            <Col md={6}>
                                <InputGroup className='search-row blurred-background blurred-background'>
                                    <form onSubmit={this.searchForDecks}>
                                        <Form.Control
                                            placeholder='Search decks...'
                                            aria-label='Search decks...'
                                            aria-describedby="card-searchField"
                                            id="DeckSearchField"
                                        />
                                        <input type="submit" variant="outline-secondary" id="button-addon2" value="" />
                                    </form>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Container>
                    <div class="overflow-auto pb-5">
                        {this.state.categories[0].decks.length > 0 || this.state.query ? this.state.categories.map(function (category, i) {
                            return (
                                <Container mb={5} key={i} >
                                    <hr></hr>
                                    <DeckBrowser_RowTitle title={category.title} isSearchQuery={this.state.query != false} />
                                    <Row>
                                        {category.decks.map(function (deck, i) {
                                            return (
                                                <DeckBrowser_Deck deck={deck} key={i} />
                                            )
                                        }, this)}
                                    </Row>
                                    {(() => {
                                        if (this.state.lastPaginationElement != false) {
                                            if (this.state.query === "top decks") {
                                                return (<button class="default-button" onClick={() => this.loadMoreTopDeck()}>Load more</button>)
                                            } else if (this.state.query === "recently updated") {
                                                return (<button class="default-button" onClick={() => this.loadMoreRecentDeck()}>Load more</button>)
                                            } else {
                                                return (<button class="default-button" onClick={() => this.loadMoreSearchDeck()}>Load more</button>)
                                            }
                                        }
                                    })()}

                                </Container>
                            )
                        }, this) : (<div></div>)}
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }
}

function DeckBrowser_Deck(props) {
    const deckInfo = props.deck
    return (
        <Col md={6} lg={4} xxl={3}>
            <Link className="deck deck-hover-animated shadowed" to={"/deck/" + deckInfo.deckId} >
                <DeckArt artId={deckInfo.artId} />
                <div class="deck-info">
                    <h1 class="d-block">{deckInfo.name}</h1>
                    <UserName uid={deckInfo.authorUuid} displayName={deckInfo.author} />
                </div>
                <span>
                    {
                        deckInfo.likesCount ? (
                            <div>
                                <p>{deckInfo.likesCount}</p>
                                <img src={require('./../../assets/Icons/coeur.png')} />
                            </div>
                        ) : (<div></div>)
                    }
                </span>
                <div class="deckVignette"></div>
                <div class="blurred-background"></div>
            </Link>
        </Col>
    )
}

function DeckBrowser_RowTitle(props) {
    return (
        <Row className='row-title'>
            {props.isSearchQuery ? (
                <p>{props.title}</p>
            ) : (
                <h2>{props.title}</h2>
            )}
            {props.title == "Recently updated" ? (
                <Link to={'/decks/public/recent'}>See all</Link>) : <p></p>
            }
            {props.title == "Most likes" ? (
                <Link to={'/decks/public/top'}>See all</Link>) : <p></p>
            }
        </Row>
    )
}

export default DeckBrowser